import { LoaderModule } from './../../shared/loader/loader.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MeetingComponent } from './meeting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MeetingClientModule } from 'src/app/shared/meeting-client/meeting-client.module';
import { ProductDisplayModule } from 'src/app/shared/product-display/product-display.module';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FormlyFieldProductSelectModule } from 'src/app/shared/formly-fields/product-select/formly-field-product-select.module';
import { MdePopoverModule } from '@material-extended/mde';
import { MeetingCancelDemoDialogComponent } from './meeting-cancel-demo-dialog/meeting-cancel-demo-dialog.component';
import { MeetingJoinDemoDialogComponent } from './meeting-join-demo-dialog/meeting-join-demo-dialog.component';
import { TokboxMeetingClientService } from 'src/app/shared/meeting-client/tokbox-meeting/service/tokbox-meeting-client.service';
import { TokboxMeetingClientModule } from 'src/app/shared/meeting-client/tokbox-meeting/tokbox-meeting-client.module';
import { RescheduleDemoModule } from 'src/app/shared/reschedule-demo/reschedule-demo.module';
import { ZoomMeetingModule } from 'src/app/shared/meeting-client/zoom-meeting/zoom-meeting.module';

const routes: Routes = [
  {
    path: 'meeting',
    redirectTo: 'error' // TODO: redirect to invalid meeting page
  },
  {
    path: 'meeting/:id',
    component: MeetingComponent
  }
];


const imports = [
  CommonModule,
  HeaderModule,
  RouterModule.forChild(routes),
  ReactiveFormsModule,
  FormlyMaterialModule,
  HeaderModule,
  FlexLayoutModule,
  MatSelectModule,
  MatButtonModule,
  MeetingClientModule,
  ProductDisplayModule,
  FormsModule,
  MatMenuModule,
  MatIconModule,
  FormlyModule,
  FormlyFieldProductSelectModule,
  MdePopoverModule,
  TokboxMeetingClientModule,
  LoaderModule,
  RescheduleDemoModule,
  ZoomMeetingModule
];

@NgModule({
  declarations: [
    MeetingComponent,
    MeetingCancelDemoDialogComponent,
    MeetingJoinDemoDialogComponent
  ],
  imports: [
    imports,

  ],
  exports: [],
  providers: [TokboxMeetingClientService]
})
export class MeetingModule { }
