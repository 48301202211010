import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../thank-you/thank-you.component';

@Component({
  selector: 'app-end-call',
  templateUrl: './end-call.component.html',
  styleUrls: ['./end-call.component.scss']
})
export class EndCallComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EndCallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onStay(): void {
    this.dialogRef.close(false);
  }
  onLeave() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void { }
}


