import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormlyModule } from '@ngx-formly/core';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { FormlyFieldMobileComponent } from './formly-field-mobile.component';

// export function PhoneValidator(control: FormControl): ValidationErrors {
//   return !control.value || /^[0-9]*$/.test(control.value) || control.value == 0 ? null : { 'phone': true };
// }
const components = [FormlyFieldMobileComponent]
@NgModule({
  declarations: [FormlyFieldMobileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,

    FormlyMatFormFieldModule,
    FormlyModule.forChild({
      // validators: [
      //   { name: 'phone', validation: PhoneValidator },
      // ],
      // validationMessages: [
      //   { name: 'phone', message: 'Enter a valid 10 digit phone number' },
      //   { name: 'required', message: 'Above field should not be empty' },
      // ],
      types: [
        {
          name: 'field-mobile',
          component: FormlyFieldMobileComponent,
          wrappers: ['form-field'],
        },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
      ],
    }),
  ],
  exports: [components]
})
export class FormlyFieldMobileModule { }
