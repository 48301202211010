import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'src/app/core/store/store';
import { environment } from 'src/environments/environment';
import { AvailableSlots, BookSlot, MatSelectOptions } from './book-slot.model';
import {
  BookSlotLoadedState,
  BookSlotLoadingErrorState,
  BookSlotLoadingState,
} from './book-slot.state';

@Injectable({ providedIn: 'root' })
export class BookSlotService extends Store.AbstractService {
  searchSlot(payload) {
    const me = this;
    let path: any = '';
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new BookSlotLoadingState());
    }, 0);
    path = environment.api.meeting.schedular.endpoint + '?' + 'date=' + payload;
    me.controller.get(path).subscribe(
      (data: BookSlot) => {
        output.next(new BookSlotLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new BookSlotLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  searchAvailableSlots(date: string): Observable<MatSelectOptions[]> {
    const me = this;
    const path =
      environment.api.meeting.schedular.endpoint + '?' + 'date=' + date;
    return me.controller
      .get(path)
      .pipe(map((data) => me.prepareSlots(data?.availableSlots)));
  }

  prepareSlots(slots: AvailableSlots[]): MatSelectOptions[] {
    const me = this;
    const options: MatSelectOptions[] = [];
    if (slots) {
      for (const slot of slots) {
        options.push({
          label: `${slot.startTime} - ${slot.endTime}`,
          value: slot,
        });
      }
    }
    return options;
  }
  bookSlot(payload) {
    const me = this;
    let path: any = '';
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new BookSlotLoadingState());
    }, 0);
    path = environment.api.meeting.schedular.endpoint;
    me.controller.post(path, payload).subscribe(
      (data: BookSlot) => {
        output.next(new BookSlotLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new BookSlotLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }
}
