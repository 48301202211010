import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RescheduleDemoComponent } from './reschedule-demo.component';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from 'src/app/shared/material-module';

const components = [RescheduleDemoComponent];
@NgModule({
  declarations: [components],
  imports: [CommonModule, MatButtonModule, MaterialModule],
  exports: [components],
})
export class RescheduleDemoModule {}
