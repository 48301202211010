import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateMeetComponent } from './create-meet.component';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormControl, ValidationErrors } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldCountryCodeSelectModule } from 'src/app/shared/formly-fields/country-code-select/formly-field-country-code-select.module';
import { FormlyFieldCountryCodeModule } from 'src/app/shared/formly-fields/country-code/formly-field-country-code.module';
import { CountryMobileModule } from 'src/app/shared/formly-fields/country-mobile/country-mobile.module';
import { FormlyFieldCheckboxModule } from 'src/app/shared/formly-fields/checkbox/formly-field-checkbox.module';
import { FormlyFieldVerifyOtpModule } from 'src/app/shared/formly-fields/verify-otp/formly-field-verify-otp.module';
import { FormlyFieldMulticheckboxModule } from 'src/app/shared/formly-fields/multicheckbox/formly-field-multicheckbox.module';
import { FormlyFieldContactModule } from 'src/app/shared/formly-fields/contact/formly-field-contact.module';
import { FormlyFieldReadOnlyModule } from 'src/app/shared/formly-fields/read-only-field/formly-field-read-only.module';


// export function NameValidator(control: FormControl): ValidationErrors {
//   return control.value ? null : { 'name': true };
// }

// export function EmailValidator(control: FormControl): ValidationErrors {
//   return !control.value || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(control.value) ? null : { 'email': true };
// }

// export function PhoneValidator(control: FormControl): ValidationErrors {
//   return !control.value || /^[7-9]\d{9}$/.test(control.value) ? null : { 'phone': true };
// }

// export function VehicleValidator(control: FormControl): ValidationErrors {
//   return !control.value ? null : { 'vehicle': true };
// }

// export function minlengthValidationMessage(err, field) {
//     return `Should have atleast ${field.templateOptions.minLength} characters`;
//   }
  
//   export function maxlengthValidationMessage(err, field) {
//     return `This value should be less than ${field.templateOptions.maxLength} characters`;
//   }



const imports = [
  CommonModule,
  MatGridListModule,
  HeaderModule,
  FormlyFieldCountryCodeSelectModule,
  FormlyFieldCountryCodeModule,
  FormlyFieldCheckboxModule,
  CountryMobileModule,
  FormlyFieldContactModule,
  FormlyFieldVerifyOtpModule,
  FormlyFieldMulticheckboxModule,
  FormlyFieldReadOnlyModule

];

const components = [
  CreateMeetComponent
];

const routes: Routes = [
  {
    path: 'create-meet',
    component: CreateMeetComponent
  }
];

@NgModule({
  declarations: [components],
  imports: [
    imports,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    FormlyModule.forRoot({
    }),
  ],
  exports: [
    RouterModule
  ]
})
export class CreateMeetModule { }

