<div class="country-code-formly">
    <form class="country-code-select-form">
        <mat-form-field class="country-code-select-form-field" appearance="outline">
            <mat-label>Country Code</mat-label>
            <mat-select matNativeControl required [formControl]="countryCodeControl">
                <mat-option *ngFor="let countryCode of countryCodes | async"
                    [value]="getManipulatedValues(countryCode?.flag, countryCode?.code)">
                    <img class="country-code-flag-img" aria-hidden [src]="countryCode?.flag"
                        onerror="this.src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPqqWS9KQAR-8S7BBzlfdtGKVaOdggFbqoew&usqp=CAU'"
                        height="18" width="35">
                    <span>&nbsp;&nbsp;{{countryCode.name}}</span>
                    <span>({{countryCode?.code}})</span>
                    <!-- <span>{{'  (+' + getCountryCallingCode(countryCode?.code) + ')'}}</span> -->
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span *ngIf="!countryCodeControl.valid && countryCodeControl.touched">This field is required</span>
    </form>
</div>