import { Component, ViewEncapsulation, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
/* ----------------------------------------------------------------------------------------- */


@Component({
  selector: 'app-formly-field-read-only',
  templateUrl: './formly-field-read-only.component.html',
  styleUrls: ['./formly-field-read-only.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldReadOnlyComponent extends FieldType implements OnInit {

  get label(): string {
    const me = this;
    return me.to?.label;
  }

  get value(): string {
    const me = this;
    return me.to?.value;
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const me = this;

    me.formControl.valueChanges.subscribe(() => {
      me.cd.detectChanges();
    });
  }
}
