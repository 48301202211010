import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FormlyFieldNameModule } from 'src/app/shared/formly-fields/name/formly-field-name.module';
import { FormlyFieldEmailModule } from 'src/app/shared/formly-fields/email/formly-field-email.module';
import { FormlyFieldMobileModule } from 'src/app/shared/formly-fields/mobile/formly-field-mobile.module';
import { FormlyFieldProductSelectModule } from 'src/app/shared/formly-fields/product-select/formly-field-product-select.module';
import { FormlyFieldCheckboxModule } from 'src/app/shared/formly-fields/checkbox/formly-field-checkbox.module';
import { FormlyFieldLastNameModule } from 'src/app/shared/formly-fields/last-name/formly-field-last-name.module';
import { SearchSlotModule } from 'src/app/shared/search-slot/search-slot.module';
import { BookSlotComponent } from './book-slot/book-slot.component';
import { BookSlotModule } from './book-slot/book-slot.module';
import { FormlyFieldProductModule } from 'src/app/shared/formly-fields/product/formly-field-product.module';
import { DemoBookedModule } from 'src/app/shared/demo-booked/demo-booked.module';

const imports = [
  CommonModule,
  HeaderModule,
  FormlyFieldNameModule,
  FormlyFieldEmailModule,
  FormlyFieldMobileModule,
  FormlyFieldProductSelectModule,
  FormlyFieldCheckboxModule,
  FormlyFieldLastNameModule,
  MatGridListModule,
  ReactiveFormsModule,
  FormlyModule,
  FormlyMaterialModule,
  MatGridListModule,
  MatCardModule,
  MatIconModule,
  FlexLayoutModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  SearchSlotModule,
  BookSlotModule,
  DemoBookedModule,
  FormlyFieldProductModule,
  FormlyModule.forRoot({
    validationMessages: [
      { name: 'required', message: 'This field is required' },
    ],
  }),
];

const components = [HomeComponent];

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [components],
  imports: [imports, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeModule {}
