import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormlyFieldCalendarComponent } from './formly-field-calendar.component';
import { MaterialModule } from '../../material-module';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGrigPlugin,
  interactionPlugin
]);

const formlyFieldMap = [{
  name: 'field-calendar',
  component: FormlyFieldCalendarComponent
}];

const components = [FormlyFieldCalendarComponent]
@NgModule({
  declarations: [FormlyFieldCalendarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MaterialModule,
    FormlyMatFormFieldModule,
    FullCalendarModule,
    FormlyModule.forRoot({ types: formlyFieldMap })    
  ],
  exports: [components]
})
export class FormlyFieldCalendarModule { }
