import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-formly-field-ngx-mat-country',
  templateUrl: './formly-field-ngx-mat-country.component.html',
  styleUrls: ['./formly-field-ngx-mat-country.component.scss']
})
export class FormlyFieldNgxMatCountryComponent implements OnInit {

  phoneForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

  submit() {
    console.log('clicked');
  }

}
