import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingClientComponent } from './meeting-client.component';
import { AgoraMeetingClientModule } from './agora-meeting/agora-meeting-client.module';



@NgModule({
  declarations: [
    MeetingClientComponent
  ],
  exports: [
    MeetingClientComponent,
    AgoraMeetingClientModule
  ],
  imports: [
    CommonModule,
    AgoraMeetingClientModule
  ]
})
export class MeetingClientModule { }
