import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reschedule-demo',
  templateUrl: './reschedule-demo.component.html',
  styleUrls: ['./reschedule-demo.component.scss']
})
export class RescheduleDemoComponent implements OnInit {
  vuPortLogo: string;
  
  constructor(
    public dialogRef: MatDialogRef<RescheduleDemoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navigator: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(){
    const me = this;
    // localStorage.setItem('rescheduleDemo','true');
    // me.navigator.navigateByUrl('/home');
    // me.dialogRef.close();
    me.navigator.navigate(['/home'], { queryParams: { demo: 'reschedule' } });
    me.dialogRef.close({ getData: false });
  }

  // cancel(): void{
  //   const me = this;
  //   // me.dialogRef.close();
  //   me.dialogRef.close({ getData: false });
  // }

}
