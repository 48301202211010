import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { ErrorComponent } from './error.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const imports = [CommonModule, MatGridListModule, FlexLayoutModule];

const components = [ErrorComponent];

const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
  },
];

@NgModule({
  declarations: [components],
  imports: [imports, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorModule {}
