import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-field-last-name',
  templateUrl: './formly-field-last-name.component.html',
  styleUrls: ['./formly-field-last-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldLastNameComponent extends FieldType implements OnInit {
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      label: 'NAME',
      placeholder: 'Enter Your Name',
      appearance: 'outline',
      minLength: 2,
      maxLength: 20,
      // pattern: /^[A-Za-z]*$/,
    },
    // validation: {
    //   messages: {
    //     pattern: (error, field: FormlyFieldConfig) => `Enter Valid Last Name`,
    //   },
    // },
    validators: {
      name: {
        expression: (c) => /^[A-Za-z]*$/.test(c.value),
        message: (error, field: FormlyFieldConfig) => "Enter a valid last name without spaces",
      },
    },
  };

  get type() {
    return this.to.type || 'text';
  }
}
