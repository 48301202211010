import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlyFieldContactComponent } from './formly-field-contact.component';
import { FormlyFieldCountryCodeSelectModule } from '../country-code-select/formly-field-country-code-select.module';

const formlyFieldMap = [
  {
    name: 'contact-field',
    component: FormlyFieldContactComponent
  }
];

const components = [
  FormlyFieldContactComponent
];

const dependencies = [
  FormsModule,
  ReactiveFormsModule,
  FormlyFieldCountryCodeSelectModule,
  CommonModule,
];


const modules = [dependencies];

@NgModule({
  declarations: components,
  entryComponents: components,
  imports: [
    modules,
    FormlyModule.forRoot({
      types: formlyFieldMap,
    })
  ],
  exports: [modules, components]
})
export class FormlyFieldContactModule { }


