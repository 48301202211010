import { Routes, RouterModule } from '@angular/router';
import { CountryMobileComponent } from './country-mobile.component';

// ROUTING
import { HttpClientModule } from '@angular/common/http';

// FORMS
import { FormsModule } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { AppRoutingModule } from 'src/app/app-routing.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormlyModule } from '@ngx-formly/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatInputModule } from '@angular/material/input';

// export function PhoneValidator(control: FormControl): ValidationErrors {
//   return !control.value || /^((?!(0))[0-9]{10})$/.test(control.value) || control.value == 0 ? null : { 'phone': true };
// }

const imports = [
  FormlyMaterialModule,
  MatButtonModule,
  MatSelectModule,
  MatFormFieldModule,
];

@NgModule({
  declarations: [CountryMobileComponent],
  imports: [
    imports,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    FormlyMatFormFieldModule,
    FormlyModule.forChild({
    //   validators: [
    //     { name: 'phone', validation: PhoneValidator },
    // ],
    // validationMessages: [
    //     { name: 'phone', message: 'Enter a valid 10 digit phone number'},
    //     { name: 'required', message: 'Above field should not be empty'},
    //   ],
      types: [
        {
          name: 'field-country-mobile',
          component: CountryMobileComponent,
          wrappers: ['form-field'],
        },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
      ],
    }),
  ],
})
export class CountryMobileModule { }
