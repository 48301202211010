import { Component, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormlyFieldVerifyOtpService } from './service/formly-field-verify-otp.service';

/* ----------------------------------------------------------------------------------------- */

@Component({
  selector: 'app-formly-field-verify-otp',
  templateUrl: './formly-field-verify-otp.component.html',
  styleUrls: ['./formly-field-verify-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class FormlyFieldVerifyOtpComponent extends FieldType implements OnInit, AfterViewInit {

  localForm: FormGroup;
  fields: FormlyFieldConfig[];
  localFormIsValid: boolean;
  loading = false;
  otpCustomError = false;
  verifyAction: boolean | string = false;

  constructor(
    private fb: FormBuilder,
    private formlyFieldVerifyOtpService: FormlyFieldVerifyOtpService
  ) {
    super();
    this.localForm = this.fb.group({}, Validators.required);
    this.form = this.fb.group({});
  }

  public ngOnInit(): void {
    const me = this;
    me.loadFormFields();

    me.localForm.valueChanges.subscribe(() => {
      me.localFormIsValid = me.localForm.valid;
      me.otpCustomError = false;
    });

    me.formControl.statusChanges.subscribe(() => {
      if (me.formControl.status === 'DISABLED') {
        me.localForm.disable({ onlySelf: true, emitEvent: false });
      }
    });

    me.localForm.valueChanges.subscribe((v) => {
      me.formControl.markAsPristine();
      me.formControl.setValue(v, { onlySelf: false, emitEvent: false });
      if (!me.localForm.valid) {
        me.formControl.setErrors({ incorrect: true });
      }
    });

  }

  private loadFormFields(): void {
    const me = this;

    me.fields = [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 verify-input',
            key: 'otp',
            type: 'input',
            templateOptions: {
              required: true,
              appearance: 'outline',
              label: 'Verify Otp',
            },
            hooks: {
              onInit(field: FormlyFieldConfig): void {
                me.form.controls.customer_contact.statusChanges.subscribe(() => {
                  field.form.reset();
                  me.reRenderComponent();
                });
              }
            }
          }
        ]
      }
    ];
  }

  ngAfterViewInit() {
    const me = this;
    me.setValidation();
  }

  setValidation() {
    const me = this;
    me.formControl.setValidators((): ValidationErrors => {
      if (!me.localForm.pristine) {
        me.formControl.markAsDirty();
      }

      if (me.localForm.invalid || me.verifyAction !== 'correctOtp') {
        return {
          incorrect: true
        };
      }
      return null;
    });

    me.formControl.updateValueAndValidity();
  }

  authenticateOTP() {
    const me = this;
    const otp = Number(me.localForm?.value?.otp);
    me.loading = true;

    // #TODO API returning boolean values
    // me.formlyFieldVerifyOtpService.verifyOTP(otp).subscribe(res => {
    // });

    if (otp === 1234) {
      me.loading = true;
      me.verifyAction = 'correctOtp';

    } else {
      me.loading = true;
      me.verifyAction = 'wrongOtp';
      me.otpCustomError = true;
      me.loading = false;
    }

    me.setValidation();
  }

  generateOTP() {
    const me = this;
    me.loading = true;
    me.verifyAction = true;

    // #TODO API returning numeric values
    // me.formlyFieldVerifyOtpService.generateOTP().subscribe(res => {
    // });
  }

  reRenderComponent() {
    const me = this;
    me.loading = false;
    me.otpCustomError = false;
    me.verifyAction = false;
  }

}
