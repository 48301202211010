import { NgModule } from '@angular/core';
import { CustomDatePipe } from './date/customdate.pipe';
import { DatePipe } from './date/date.pipe';
import { NewDatePipe } from './date/newdate.pipe';
import { UserProfileDatePipe } from './date/userprofiledate.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { TimeConvertPipe } from './time-convert/time-convert.pipe';
import { TimePipe } from './time/time.pipe';

const pipes = [
    DatePipe,
    TimeConvertPipe,
    EllipsisPipe,
    TimePipe,
    CustomDatePipe,
    NewDatePipe,
    UserProfileDatePipe
];


@NgModule({
    declarations: [pipes],
    exports: [pipes]
})
export class PipesModule { }
