<input *ngIf="type !== 'number'; else numberTmp" matInput [id]="id" [type]="type || 'text'" [readonly]="to.readonly"
  [required]="to.required" [errorStateMatcher]="errorStateMatcher" [formControl]="formControl"
  [formlyAttributes]="field" [tabindex]="to.tabindex" [placeholder]="to.placeholder" />
<i *ngIf="to.editable" class="pass-icon" (click)="editField()" [ngClass]="passwordIcon"></i>
<ng-template #numberTmp>
  <input matInput [id]="id" type="number" onKeyPress="if(this.value.length==10) return false; "
  (keydown)="onKeypressEvent($event)" [readonly]="to.readonly" [required]="to.required"
    [errorStateMatcher]="errorStateMatcher" [formControl]="formControl" [formlyAttributes]="field"
    [tabindex]="to.tabindex" [placeholder]="to.placeholder" />
</ng-template>
<!-- (keypress)="onKeypressEvent($event)" -->