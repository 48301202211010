import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-field-email',
  templateUrl: './formly-field-email.component.html',
  styleUrls: ['./formly-field-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormlyFieldEmailComponent extends FieldType implements OnInit {
  passwordIcon: string = 'icons8-edit'
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      type: 'email',
      label: 'EMAIL ID',
      placeholder: 'Enter Your Email ID',
      appearance: 'outline',
      // pattern: /[a-zA-Z0-9._-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,4}/,
    },
    // validation: {
    //   messages: {
    //     pattern: (error, field: FormlyFieldConfig) => `Enter Valid Email`,
    //   },
    // },
    validators: {
      name: {
        expression: (c) => /[a-zA-Z0-9._-]{2,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,4}/.test(c.value),
        message: (error, field: FormlyFieldConfig) => "Enter a valid email id",
      },
    },
  };

  get type() {
    return this.to.type || 'text';
  }
  ngOnInit() {
    const me = this;
    if (me.to.editable) {
      me.to.disabled = true;
    }
  }
  editField() {
    const me = this;
    me.to.disabled = !me.to.disabled;
  }
}

