import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TokboxMeetingClientComponent } from './tokbox-meeting-client.component';
import { MaterialModule } from '../../material-module';
import { HeaderModule } from '../../header/header.module';
import { ProductDisplayModule } from '../../product-display/product-display.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MeetingChatModule } from '../meeting-chat/meeting-chat.module';
import { EllipsisModule } from 'ngx-ellipsis';
import { PipesModule } from '../../pipes/pipes.module';

const modules = [
  CommonModule,
  MaterialModule,
  HeaderModule,
  ProductDisplayModule,
  FlexLayoutModule,
  MeetingChatModule,
  PipesModule,
  EllipsisModule
];

const components = [
  TokboxMeetingClientComponent,
];

@NgModule({
  declarations: [components],
  imports: [modules],
  exports: [components, modules],

})
export class TokboxMeetingClientModule { }
