import { Store } from 'src/app/core/store/store';
import { AvailableSlots, BookSlot, CreateMeetLoadPayload } from './book-slot.model';

export class BookSlotLoadingState extends Store.AbstractLoadingState<BookSlot> {}
export class BookSlotLoadingErrorState extends Store.AbstractErrorState<BookSlot> {}
export class BookSlotLoadedState extends Store.AbstractIdealState<BookSlot> {}

export class CreateMeetLoadingState extends Store.AbstractLoadingState<CreateMeetLoadPayload> {}
export class CreateMeetLoadingErrorState extends Store.AbstractErrorState<CreateMeetLoadPayload> {}
export class CreateMeetLoadedState extends Store.AbstractIdealState<CreateMeetLoadPayload> {}

export class AvailableBookSlotLoadingState extends Store.AbstractLoadingState<AvailableSlots> {}
export class AvailableBookSlotLoadingErrorState extends Store.AbstractErrorState<AvailableSlots> {}
export class AvailableBookSlotLoadedState extends Store.AbstractIdealState<AvailableSlots> {}