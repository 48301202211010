import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-booked',
  templateUrl: './demo-booked.component.html',
  styleUrls: ['./demo-booked.component.scss']
})
export class DemoBookedComponent implements OnInit {
  vuPortLogo: string;
  
  constructor(
    public dialogRef: MatDialogRef<DemoBookedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navigator: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(){
    const me = this;
    console.log('Demo Booked Successfully');
    me.dialogRef.close({ getData: false });
  }

  // cancel(): void{
  //   const me = this;
  //   me.dialogRef.close({ getData: false });
  // }

}
