import { FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxAgoraModule } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { AgoraMeetingClientComponent } from './agora-meeting-client.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MeetingChatModule } from '../meeting-chat/meeting-chat.module';
import { ProductDisplayModule } from '../../product-display/product-display.module';


const imports = [
  CommonModule,
  HeaderModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  FormsModule,
  FlexLayoutModule,
  NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
  MatDialogModule,
  HeaderModule,
  FormlyModule.forRoot({
    validationMessages: [
      { name: 'required', message: 'This field is required' },
    ],
  }),
  MatSidenavModule,
  MatCardModule,
  MeetingChatModule,
  ProductDisplayModule
];

const components = [
  AgoraMeetingClientComponent
];

@NgModule({
  declarations: [components],
  imports: [
    imports
  ],
  providers: [],
  exports: [
    RouterModule,
    components],
  entryComponents: []
})
export class AgoraMeetingClientModule { }
