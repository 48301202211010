import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FormlyFieldCountryCodeSelectService {
    constructor(private http: HttpClient) { }

    getCountryCodes(): Observable<any> {
        const me = this;
        return this.http.get(`assets/json/countries.json`)
            .pipe(
                map(json => (me.modifyJson(json)))
            );
    }

    private modifyJson(jsonData): any[] {
        const statusObj = [];
        jsonData.forEach(v => (statusObj.push(
            { code: v.code, name: v.name, flag: `https://cdn.countryflags.com/thumbs/${v.name.toLowerCase()}/flag-800.png` })));
        return statusObj;
    }
}
