import { Injectable } from '@angular/core';
import * as OT from '@opentok/client';
import { TokboxConfig } from './tokbox-meeting-client.model';

@Injectable()
export class TokboxMeetingClientService {

    session: OT.Session;
    token: string;

    constructor() { }

    getOT() {
        return OT;
    }

    initSession(config: TokboxConfig) {
        const me = this;

        if (config.apiKey && config.token && config.sessionId) {
            me.session = me.getOT().initSession(config.apiKey, config.sessionId);
            me.token = config.token;
            return Promise.resolve(me.session);
        } else {
            return fetch(config.SAMPLE_SERVER_BASE_URL + '/session')
                .then((data) => data.json())
                .then((json) => {
                    me.session = me.getOT().initSession(json.apiKey, json.sessionId);
                    me.token = json.token;
                    return me.session;
                });
        }
    }

    connect() {
        const me = this;

        return new Promise((resolve, reject) => {
            me.session.connect(me.token, (err) => {
                if (err) {
                    reject(err);
                } else {
                    console.log("connected");
                    resolve(me.session);
                }
            });
        });
    }
}