import { Store } from 'src/app/core/store/store';

export const environment = {
  production: false,
  agora: {
    appId: '8e52f8ad7a2543c887e1e9faecdc893e',
    token:
      '0068e52f8ad7a2543c887e1e9faecdc893eIABnM+nso46ghHlT3MzVgdikMxbqWplxJVZ0ZafytZnrBTnFxnIAAAAAEAAzuiAFOLBlXwEAAQA6r2Vf',
  },
  localstorage: {
    key: 'vp_storage',
  },
  api: {
    core: {
      base: '/api',
    } as Store.APIConfig,

    // core: {
    //   base: ' https://misf3s5a0k.execute-api.us-east-2.amazonaws.com/prod/api',
    //   // defaultBase: ' https://misf3s5a0k.execute-api.us-east-2.amazonaws.com/prod/api',   //demo instance
    //   // defaultBase: ' https://kiq4cabzz0.execute-api.us-east-2.amazonaws.com/prod/api',     // dev instance
    //   //defaultBase: 'http://localhost/directus/public/hemanttool/',
    //   endpoint: '', //Base URL for HT

    // } as Store.APIConfig,

    session: {
      login: {
        endpoint: '/auth/adminLogin',
      },
      logout: {
        endpoint: '/auth/logout',
      },
      validate: {
        endpoint: '/auth/validate',
      },
      forgotPassword: {
        endpoint: '/auth/forgotPassword',
      },
      newPassword: {
        endpoint: '/auth/setNewPassword',
      },
    } as Store.APIConfigGroup,

    site: {
      load: {
        endpoint: '/site',
      },
      updateSiteStatus: {
        endpoint: '/site/update-site-status',
      },
      updateSite: {
        endpoint: '/site/update-site-weekly-schedule',
      },
      getSite: {
        endpoint: '/site/site-weekly-schedule',
      },
      checkAvialibility: {
        endpoint: '/site/update-routing-algo',
      },
      scheduleMeeting: {
        endpoint: '/site/update-site-scheduler-config',
      },
      licenseData: {
        endpoint: '/site/licenseData',
      },
    } as Store.APIConfigGroup,

    meeting: {
      join: {
        endpoint: '/meeting/join/prospect',
      },
      load: {
        endpoint: '/meeting',
      },
      cancel: {
        endpoint: '/meeting/cancel/prospect',
      },
      feedback: {
        endpoint: '/meeting/feedback/prospect',
      },
      schedular: {
        endpoint: '/scheduler',
      },
      details: {
        endpoint: '/meeting/details',
      },
      updateMeeting: {
        endpoint: '/meeting/updateMeeting'
      },
      leads: {
        endpoint: '/leads',
      },
    } as Store.APIConfigGroup,

    appointments: {
      list: {
        endpoint: '/meeting/list2',
      },
      customerDetails: {
        endpoint: '/meeting/details',
      },
    } as Store.APIConfigGroup,

    dashboard: {
      list: {
        endpoint: '/dashboard',
      },
      histogram: {
        endpoint: '/dashboard/dayGraph',
      },
      histogramBarGraph: {
        endpoint: '/dashboard/histogram',
      },
      histogramSe: {
        endpoint: '/dashboard/dayGraph-se',
      },
      line: {
        endpoint: '/dashboard/timeGraph',
      },
      pie: {
        endpoint: '/dashboard/pieChart',
      },
      topSE: {
        endpoint: '/dashboard/top-se',
      },
      salesLeaderboard: {
        endpoint: '/dashboard/top-se-dashboard',
      },
      // customerDetails: {
      //   endpoint: '/meeting/details?{meetingId}'
      // },
    } as Store.APIConfigGroup,

    salesExecutive: {
      add: {
        endpoint: '/sales-executive/add-se',
      },
      addAdmin: {
        endpoint: '/sales-executive/add-admin',
      },
      getAdmin: {
        endpoint: '/sales-executive/admin',
      },
      editAdmin: {
        endpoint: '/sales-executive/update-admin',
      },
      list: {
        endpoint: '/sales-executive',
      },
      portalUsers: {
        endpoint: '/sales-executive/admin-list',
      },
      resetSecret: {
        endpoint: '/auth/changePassword',
      },
      forceLogout: {
        endpoint: '/sales-executive/force-logout',
      },
      customerDetails: {
        endpoint: '/sales-executive/se-detail',
      },
      deviceId: {
         endpoint: '/sales-executive/device-details',
      },
      resetPassword: {
        endpoint: '/sales-executive/reset-secret',
      },
    } as Store.APIConfigGroup,

    teamManagement: {
      list: {
        endpoint: '/sales-executive/list',
      },
      deviceDetails: {
        endpoint: '/sales-executive/device-details',
      },
    } as Store.APIConfigGroup,

    profile: {
      admin: {
        endpoint: '/auth/profile',
      },
    } as Store.APIConfigGroup,

    setting: {
      schedule: {
        endpoint: '/site-schedule',
      },
      getMeetingSchedules: {
        endpoint: '/site-schedule/meetingSchedules',
      }
    } as Store.APIConfigGroup,

    notification: {
      endpoint: '/notifications',
    },
    archiving: {
      customerDetails: {
        endpoint: '/tokbox-archive/details',
      },
      transcription: {
        endpoint: '/tokbox-archive/transcription-details',
      },
      emotions: {
        endpoint: '/tokbox-archive/emotions',
      },
    },
    licenseDetails: {
      endpoint: '/device/generate-qr',
    },
  
    license: {
      site: {
        endpoint: '/site/activate-license',
      },
      isLicenseActive: {
        endpoint: '/site/is-site-license-active',
      },
      licenseData: {
        endpoint: '/site/licenseData',
      }
    },
    
    activityLogs: {
      list: {
        endpoint: '/activity-log/list',
      }, 
    },
    device: {
      list: {
        endpoint: '/device',
      },
      updateDevice:{
        endpoint: '/device/updateDevice'
      }
    } as Store.APIConfigGroup,
  },
  // licenseApi:{
  //   site: {
  //     activateLicense: {
  //       endpoint: '/prod/api/site/activate-license',
  //     },
  //     activateLicenseBasePath:{
  //       //endpoint: 'https://kiq4cabzz0.execute-api.us-east-2.amazonaws.com'
  //       //endpoint: window.location.origin,
  //     }
  //   } as Store.APIConfigGroup,
  // },

  buildVersion: {
    buildNo: '0.2.1',
  },

  formats: {
    date: {
      // Moment JS date formats
      default: 'MM-DD-YYYY',
      long: 'D-MMM-YYYY',
      short: 'D-MMM-YYYY',
    },
    dateTime: {
      // Moment JS date formats
      default: 'MM-DD-YYYY HH:mm:ss',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
