import { TokboxMeetingClientService } from './../../shared/meeting-client/tokbox-meeting/service/tokbox-meeting-client.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  MeetingLobbyLoadingState,
  MeetingLobbyLoadingErrorState,
  MeetingLobbyLoadedState,
} from './service/meeting.state';
import {
  MeetingLobby,
  MeetingJoinPayload,
  MeetingMessage,
  MeetingConnection,
  MeetingEvent,
} from './service/meeting.model';
import { MeetingLobbyService } from './service/meeting.service';
import { Store } from 'src/app/core/store/store';
import { Observable, Subject, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MeetingClientComponent } from 'src/app/shared/meeting-client/meeting-client.component';
import { AgoraMeetingClientComponent } from 'src/app/shared/meeting-client/agora-meeting/agora-meeting-client.component';
import { SessionService } from 'src/app/core/session/session.service';
import { MixpanelService } from 'src/app/shared/common/services/mixpanel.service';
import { MeetingCancelDemoDialogComponent } from './meeting-cancel-demo-dialog/meeting-cancel-demo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MeetingJoinDemoDialogComponent } from './meeting-join-demo-dialog/meeting-join-demo-dialog.component';
import { TokboxMeetingClientComponent } from 'src/app/shared/meeting-client/tokbox-meeting/tokbox-meeting-client.component';
import { RescheduleDemoComponent } from 'src/app/shared/reschedule-demo/reschedule-demo.component';
import { MeetingNotAvailableComponent } from 'src/app/shared/meeting-not-available/meeting-not-available.component';
import { ZoomMeetingComponent } from 'src/app/shared/meeting-client/zoom-meeting/zoom-meeting.component';
import { ZoomMeetingSdkComponent } from 'src/app/shared/meeting-client/zoom-meeting-sdk/zoom-meeting-sdk.component';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingComponent implements OnInit, OnDestroy, AfterViewInit {
  data: MeetingLobby;
  error: boolean;
  empty: boolean;
  loading: boolean;
  site: any;
  selectedProduct: any;
  url: string;
  id: string;
  meetingId: any;
  meetingType: string;

  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @ViewChild('meetingClientContainer', { read: ViewContainerRef })
  meetingClientContainer: ViewContainerRef;
  meetingClient: MeetingClientComponent;
  meetingConnection: MeetingConnection;

  eventsSubject: Subject<void> = new Subject<void>();
  publisher: OT.Publisher;
  location: any;
  constructor(
    private route: ActivatedRoute,
    public sessionService: SessionService,
    private cfr: ComponentFactoryResolver,
    private meetingService: MeetingLobbyService,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog,
    private router: Router,
    private tokboxMeetingClientService: TokboxMeetingClientService
  ) {
    this.empty = true;
    this.audioVideoValue = { audioValue: true, videoValue: true };
  }
  allowAudio: boolean = true;
  allowVideo: boolean = true;
  audioVideoValue: any;
  errormsg: any;

  isSEJoined: boolean = false;
  clearTimeoutForSE: any;

  emitEventToChild() {
    this.eventsSubject.next();
  }
  //  1st 
  private initMeeting(): boolean {
    console.log(`meeting comp initMeeting---------->`)
    const me = this;

    // Do not initiate meeting if connection is not possible.
    if (me.data.connect) {
      // Clear Container Content
      me.meetingClientContainer.clear();

      let cf: ComponentFactory<MeetingClientComponent> = null;

      console.log(me.data.config)

      // Agora specific meeting client component
      if (me.data.config.agora) {
        cf = me.cfr.resolveComponentFactory(AgoraMeetingClientComponent);
      }

      if (cf) {
        const ccr: ComponentRef<MeetingClientComponent> =
          me.meetingClientContainer.createComponent(cf);
        ccr.instance.init(me.data, me.meetingConnection);
        me.meetingClient = ccr.instance;
        return true;
      }
      // Tokbox specific meeting client component
      if (me.data.config.tokbox) {
        cf = me.cfr.resolveComponentFactory(TokboxMeetingClientComponent);
      }
      if (cf) {
        const ccr: ComponentRef<MeetingClientComponent> =
          me.meetingClientContainer.createComponent(cf);
        me.publisher.publishVideo(false);
        me.publisher.publishAudio(false);

        console.log(`meeting comp initMeeting before init---------->`)

        ccr.instance.init(me.data, me.meetingConnection);
        me.meetingClient = ccr.instance;
        return true;
      }

      // Zoom specific meeting client component
      if (me.data.config.zoom) {
        console.log(`meeting comp initMeeting Zoom---------->`)
        console.log(me.data.config)
        cf = me.cfr.resolveComponentFactory(ZoomMeetingComponent);
      }
      if (cf) {
        const ccr: ComponentRef<MeetingClientComponent> =
          me.meetingClientContainer.createComponent(cf);
       
        ccr.instance.init(me.data, me.meetingConnection);
        me.meetingClient = ccr.instance;
        return true;
      }

      // ZoomMeetingSDK client component
      if (me.data.config.ZoomMeetingSDK) {
        console.log(`ZoomMeetingSDK---------->`)
        console.log(me.data.config)
        console.log("Meeting ID======>", me.meetingId);
        cf = me.cfr.resolveComponentFactory(ZoomMeetingSdkComponent);
      }
      if (cf) {
        const ccr: ComponentRef<MeetingClientComponent> =
          me.meetingClientContainer.createComponent(cf);

        ccr.instance.init(me.data, me.meetingConnection);
        me.meetingClient = ccr.instance;
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    console.log(`meeting comp ngOnInit---------->`)
    const me = this;
    sessionStorage.setItem(
      'audioVideoValue',
      JSON.stringify(me.audioVideoValue)
    );
    // setTimeout(() => {
    //   alert('sorry...not able to join');
    // }, 3000);

    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function (event) {
      history.pushState(null, document.title, location.href);
    });
  }

  async ngAfterViewInit() {
    console.log(`meeting comp ngAfterViewInit---------->`)
    const me = this;
    let meetingid;
    me.route.params.subscribe((params: Params) => {
      meetingid = params.id;
    });
    await me.getMeetingStatus({
      id: meetingid,
      first: true
    });
    // Connect socket
    // me.initMeetingConnection({
    //   id: meetingid,
    // });
    const OT = me.tokboxMeetingClientService.getOT();

    me.publisher = OT.initPublisher(me.publisherDiv.nativeElement, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
    });
  }

  ngOnDestroy(): void {
    const me = this;

    me.meetingClientContainer.clear();
  }

  initMeetingConnection(payload: MeetingJoinPayload) {
    console.log(`meeting comp initMeetingConnection---------->`)
    const me = this;
    // await me.getMeetingStatus(payload);
    me.meetingConnection = this.meetingService.connect(payload.id);

    let creds = {
      id: payload.id,
      first: false //first will be initially false
    };

    me.meetingJoin(creds);
    console.log("meeting!!", payload);
    me.meetingId = payload.id;

    me.meetingConnection.sendMessageFrom(MeetingEvent.ParticipantWaitingToJoin);

    me.meetingConnection
      .on(MeetingEvent.ParticipantJoin)
      .subscribe((message: MeetingMessage) => {
        if (!message.isMine) {
          me.isSEJoined = true;
          let creds = {
            id: payload.id,
            first: true //after connection first will be true
          };
          me.meetingJoin(creds);
        }
      });
  }

  getMeetingStatus(payload: MeetingJoinPayload) {
    const me = this;

    me.meetingService.getMeetingStatus(payload).subscribe(
      (state: Store.State) => {
        if (state instanceof MeetingLobbyLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof MeetingLobbyLoadedState) {
          me.onLoaded(state);
          var meetingStatus = state.data;
          me.meetingType = meetingStatus.meeting_type;
          console.log("live or scheduled", me.meetingType);
          me.initMeetingConnection(payload);
          return;
        }
      },
      (state: MeetingLobbyLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );

  }

  updateMissedMeeting(payload: MeetingJoinPayload) {
    const me = this;
    me.meetingService.updateMeeting(payload).subscribe(
      (state: Store.State) => {
        if (state instanceof MeetingLobbyLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof MeetingLobbyLoadedState) {
          me.onLoaded(state);
          // var meetingStatus = state.data;
          // me.meetingType = meetingStatus.meeting_type;
          console.log("updated meeting state", state);
          return;
        }
      },
      (state: MeetingLobbyLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  cancelDemo() {
    const me = this;
    me.mixpanelService.track('Cancel Demo');
    me.dialog.open(MeetingCancelDemoDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        meetingID: me.route.snapshot.paramMap.get('id'),
      },
    });
  }

  openRescheduleDemoComponent() {
    const me = this;

    const dialogRef = this.dialog.open(RescheduleDemoComponent, {
      width: '320px',
      panelClass: 'my-custom-dialog-class',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.getData === false) {
        me.updateMissedMeeting(me.meetingId);
      }
    });

  }

  meetingJoin(payload: MeetingJoinPayload): void {
    console.log(`meeting comp meetingJoin---------->`)
    const me = this;
    if (me.data && me.data.connect) {
      return;
    }

    me.meetingService.join(payload).subscribe(
      (state: Store.State) => {
        if (state instanceof MeetingLobbyLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof MeetingLobbyLoadedState) {
          me.onLoaded(state);
          // if(me.data.error) {
          //   alert(me.data.error);
          // }
          console.log("data connection", me.data);
          // if (me.data.connect == false) {
          //   if (me.meetingType == "live") {
          //     me.clearTimeoutForSE = setTimeout(function () { me.openRescheduleDemoComponent() }, 5 * 60 * 1000);
          //   }
          //   else {
          //     me.clearTimeoutForSE = setTimeout(function () { me.openRescheduleDemoComponent() }, 10 * 60 * 1000);
          //   }
          // }
          // else {
          //   clearTimeout(me.clearTimeoutForSE);
          // }

          return;
        }
      },
      (state: MeetingLobbyLoadingErrorState) => {
        me.onLoadingError(state);
        // console.log(state);
        me.errormsg = state.error;
        // console.log(me.errormsg.error);

        if (me.errormsg.status = 403) {
          me.openMeetingNotAvailableComponent();
        }
      }
    );
  }

  openMeetingNotAvailableComponent() {
    const me = this;
    const dialogRef = me.dialog.open(MeetingNotAvailableComponent, {
      // width: '50%'
      panelClass: 'my-custom-dialog-class',
      disableClose: true
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result && result.getData === false) {
    //     me.slotEvents.emit({ action: 'submit' });
    //   }
    // });

  }

  private onLoading(state: MeetingLobbyLoadingState) {
    const me = this;
    me.data = null;
    me.empty = true;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: MeetingLobbyLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = true;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: MeetingLobbyLoadedState) {
    const me = this;
    me.data = state.data;
    console.log(me.data);
    me.error = false;
    me.loading = false;

    if (me.data.connect) {
      me.empty = false;
      // me.dialog.open(MeetingJoinDemoDialogComponent, {
      //   width: '400px',
      //   disableClose: true,
      // });
      // me.dialog.afterAllClosed.subscribe(() => {

      // });
      me.initMeeting();
    } else {
      // Set Empty true since connection is not possible
      me.empty = true;
    }
  }

  // TODO: Integrate product details api
  initLoad() {
    const me = this;
    me.site = me.sessionService.site;
  }
  public toggleAudio(value: boolean): void {
    const me = this;
    const newAudioVideoValue = me.audioVideoValue;
    if (value === true) {
      newAudioVideoValue.audioValue = value;
      sessionStorage.setItem(
        'audioVideoValue',
        JSON.stringify(newAudioVideoValue)
      );
      me.allowAudio = true;
      me.publisher.publishAudio(true);
    } else {
      newAudioVideoValue.audioValue = false;
      sessionStorage.setItem(
        'audioVideoValue',
        JSON.stringify(newAudioVideoValue)
      );
      me.allowAudio = false;
      me.publisher.publishAudio(false);
    }
  }
  public toggleVideo(value: boolean): void {
    const me = this;
    const newAudioVideoValue = me.audioVideoValue;

    value = !value;

    if (value === true) {
      newAudioVideoValue.videoValue = value;
      sessionStorage.setItem(
        'audioVideoValue',
        JSON.stringify(newAudioVideoValue)
      );
      me.allowVideo = true;
      me.publisher.publishVideo(true);
    } else {
      newAudioVideoValue.videoValue = false;
      sessionStorage.setItem(
        'audioVideoValue',
        JSON.stringify(newAudioVideoValue)
      );
      me.allowVideo = false;
      me.publisher.publishVideo(false);
    }
  }
}
