import { Component, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isValidNumberForRegion } from 'libphonenumber-js';

/* ----------------------------------------------------------------------------------------- */

@Component({
  selector: 'app-formly-field-contact',
  templateUrl: './formly-field-contact.component.html',
  styleUrls: ['./formly-field-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class FormlyFieldContactComponent extends FieldType implements OnInit, AfterViewInit {

  localForm: FormGroup;
  fields: FormlyFieldConfig[];

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.localForm = this.fb.group({}, Validators.required);
    this.form = this.fb.group({});
  }

  public ngOnInit(): void {
    const me = this;
    me.loadFormFields();

    me.formControl.statusChanges.subscribe(() => {
      if (me.formControl.status === 'DISABLED') {
        me.localForm.disable({ onlySelf: true, emitEvent: false });
      }
    });

    me.localForm.valueChanges.subscribe((v) => {
      me.formControl.markAsPristine();
      me.formControl.setValue(v, { onlySelf: false, emitEvent: false });
      if (!me.localForm.valid) {
        me.formControl.setErrors({ incorrect: true });
      }
    });

  }

  private loadFormFields(): void {
    const me = this;
    me.fields = [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'code-input',
            key: 'code',
            type: 'country-code-field',
            templateOptions: {
              label: 'Country Code',
              required: true
            }
          },
          {
            className: 'mobileNumber-input ',
            key: 'mobileNumber',
            type: 'number',
            templateOptions: {
              appearance: 'outline',
              placeholder: 'Enter a phone number',
              label: 'Phone Number',
              type: 'string',
              required: true
            },
            validators: {
              mobileNumber: {
                expression: (form: FormControl, field: FormlyFieldConfig) => {
                  const code = me.localForm?.value?.code;
                  const mobileNumber = field?.formControl?.value?.toString();

                  if (typeof mobileNumber !== 'string') {
                    return;
                  } else {
                    return isValidNumberForRegion(mobileNumber, code) ? true : false;
                  }
                },
                message: () => `Please provide valid number`,
              }
            },
            hooks: {
              onInit(field?: FormlyFieldConfig): void {
                me.localForm.get('code').valueChanges.subscribe(() => {
                  me.localForm.get(field.key as string).reset();
                });
              }
            }
          }
        ]
      }
    ];
  }

  ngAfterViewInit() {
    const me = this;
    me.setValidation();
  }

  setValidation() {
    const me = this;
    me.formControl.setValidators((): ValidationErrors => {
      if (!me.localForm.pristine) {
        me.formControl.markAsDirty();
      }

      if (me.localForm.invalid) {
        return {
          incorrect: true
        };
      }
      return null;
    });
  }

}
