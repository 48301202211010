import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyFieldProductComponent } from './formly-field-product.component';
import { MaterialModule } from '../../material-module';

// export function NameValidator(control: FormControl): ValidationErrors {
  // return control.value ? null : { 'name': true };
  // const isWhitespace =
  //   ((control && control.value && control.value.toString()) || '').trim()
  //     .length === 0;
//   return /^[A-Za-z]*$/
// }

export function minlengthValidationMessage(err, field) {
  return `${field.templateOptions.label} must be between ${field.templateOptions.minLength}-${field.templateOptions.maxLength} characters`;
}

export function maxlengthValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

const components = [FormlyFieldProductComponent]
@NgModule({
  declarations: [FormlyFieldProductComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MaterialModule,
    FormlyMatFormFieldModule,
    FormlyModule.forChild({
      // validators: [{ name: 'name', validation: NameValidator }],
      // validationMessages: [
      //   { name: 'product', message: 'Enter a valid product' },
      //   { name: 'required', message: 'Above field should not be empty' },
      //    { name: 'whitespace', message: 'Whitespace is not allowed, Please enter valid details' },
      // ],
      types: [
        {
          name: 'field-product',
          component: FormlyFieldProductComponent,
          wrappers: ['form-field'],
        },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
      ],

    }),
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
      ],
    }),
  ],
  exports: [components]
})
export class FormlyFieldProductModule { }
