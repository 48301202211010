import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingChatComponent } from './meeting-chat.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';



const imports = [
  CommonModule,
  MatCardModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  FormlyModule.forRoot({
    validationMessages: [
      { name: 'required', message: 'This field is required' },
    ],
  }),
  MatButtonModule,
  MatChipsModule
];

@NgModule({
  declarations: [MeetingChatComponent],
  exports: [MeetingChatComponent],
  imports: [
    CommonModule,
    imports
  ]
})
export class MeetingChatModule { }
