import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material-module';
import { FormlyModule } from '@ngx-formly/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyFieldMobileModule } from 'src/app/shared/formly-fields/mobile/formly-field-mobile.module';
import { ZoomMeetingComponent } from './zoom-meeting.component';
import { MeetingChatModule } from '../meeting-chat/meeting-chat.module';
import { HeaderModule } from '../../header/header.module';
import { ProductDisplayModule } from '../../product-display/product-display.module';
import { PipesModule } from '../../pipes/pipes.module';
import { EllipsisModule } from 'ngx-ellipsis';



const components = [ZoomMeetingComponent];

const dependencies = [CommonModule, FormsModule];

const modules = [
  dependencies,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  FormlyModule,
  FlexLayoutModule,
  FormlyFieldMobileModule,
  MeetingChatModule,
  HeaderModule,
  ProductDisplayModule,
  PipesModule,
  EllipsisModule
];

@NgModule({
  declarations: [components],
  entryComponents: components,
  imports: [modules],
  exports: [modules, components],
})
export class ZoomMeetingModule {}
