import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { AgoraClient, ClientEvent, NgxAgoraService, Stream } from 'ngx-agora';
import { MatDialog } from '@angular/material/dialog';
import { MeetingConnection, MeetingEvent, MeetingLobby } from 'src/app/pages/meeting/service/meeting.model';
import { MeetingClientComponent } from '../meeting-client.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/core/session/session.service';
import { MixpanelService } from '../../common/services/mixpanel.service';

interface AgoraRemoteStream {
  id: string;
  name: string;
  video: boolean;
  audio: boolean;
  stream: Stream;
}


@Component({
  selector: 'app-agora-meeting-client',
  templateUrl: './agora-meeting-client.component.html',
  styleUrls: ['./agora-meeting-client.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MeetingClientComponent, useExisting: AgoraMeetingClientComponent },
  ]
})
export class AgoraMeetingClientComponent extends MeetingClientComponent implements OnDestroy {

  @ViewChild('remotes', { read: ElementRef }) remotesContainerEle: ElementRef;

  public localStream: Stream;
  private client: AgoraClient;

  // Channel (meeting room) within the Agora app to join
  channel: string;

  // All the IDs of other users that have joined the call
  remoteStreams: Map<string, AgoraRemoteStream> = new Map<string, AgoraRemoteStream>();

  // Whether the local client has tuned in to the Agora meeting room
  connected: boolean;

  // Whether the local client's A/V stream has been published to the remote meeting room
  published: boolean;

  allowVideo: boolean;

  allowAudio: boolean;

  socketConnectionLost: boolean;

  get localStreamElementId(): string {
    return this.id + '-local-stream';
  }

  constructor(
    public sessionService: SessionService,
    private agoraService: NgxAgoraService,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    private mixpanelService: MixpanelService,
  ) {
    super(sessionService, router, route);
    const me = this;
    me.setDefaults();
  }

  ngOnDestroy() {
    const me = this;
    me.leave();
  }

  private setDefaults() {
    const me = this;
    me.remoteStreams.clear();
    me.connected = false;
    me.published = false;
    me.allowVideo = true;
    me.allowAudio = true;
  }

  public init(meeting: MeetingLobby, meetingConnection: MeetingConnection): boolean {
    const me = this;

    if (super.init(meeting, meetingConnection)) {

      me.client = me.agoraService.createClient({ mode: 'rtc', codec: 'h264' });

      me.assignClientHandlers();

      if (me.meeting.config.agora.channel) {
        me.join();
      }
      return true;
    }

    return false;
  }

  public join(): void {
    const me = this;


    me.client.init(me.meeting.config.agora.appId, () => {
      me.connected = true;

      me.client.join(
        me.meeting.config.agora.token,
        me.meeting.config.agora.channel,
        null,
        (uid: string) => {
          me.id = uid;
          me.meetingConnection.sendMessageFrom(MeetingEvent.ParticipantJoin, { streamId: me.id, name: me.id }); // TODO: Send prospects name
          me.publishLocalStream();
        }
      );

    }, (e) => {
      console.error(e);
    });


  }

  public toggleVideo(value: boolean): void {
    const me = this;

    if (value === true) {
      me.allowVideo = true;
      me.localStream.unmuteVideo();
    } else {
      me.allowVideo = false;
      me.localStream.muteVideo();
    }
  }

  public toggleAudio(value: boolean): void {
    const me = this;

    if (value === true) {
      me.allowAudio = true;
      me.localStream.unmuteAudio();
    } else {
      me.allowAudio = false;
      me.localStream.muteAudio();
    }
  }

  public leave(): void {
    const me = this;

    if (me.connected) {
      me.unpublishLocalStream();
      me.client.leave(
        () => {
          me.mixpanelService.track('Call End');
          me.connected = false;
          me.published = false;
          me.remoteStreams.clear();
          me.router.navigate(['.', 'feedback'], { relativeTo: me.route });
        },
        err => {
          console.error('Leave channel failed');
        }
      );
    } else {
      me.agoraService.AgoraRTC.Logger.warning('Local client is not connected to channel.');
    }
  }

  remoteTrackBy(index: number, stream: any): string {
    return stream.value.id;
  }

  private localStreamInit(callback: () => void): void {
    const me = this;
    me.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        me.localStream.play(me.localStreamElementId);
        me.connected = true;
        callback();
      },
      (err: Error) => {
        // me.trigger(MeetingEvent.LocalStreamMediaAccessNotAllowedError, null, AppError.fromError(err));
      }
    );
  }


  private initLocalStream() {
    const me = this;
    me.localStream = me.agoraService.createStream({ streamID: me.id, audio: true, video: true, screen: false });
  }

  private publishLocalStream(): void {
    const me = this;

    me.initLocalStream();

    me.localStreamInit(() => {
      me.client.publish(me.localStream, (err: Error) => {
        console.error('Publish local stream error: ' + err);
      });
    });

  }

  private unpublishLocalStream(): void {
    const me = this;
    me.localStream.muteAudio();
    me.localStream.muteVideo();
    me.localStream.stop();
    me.localStream.close();
    me.client.unpublish(me.localStream, error => console.error(error));
    me.published = false;
  }

  private assignClientHandlers(): void {
    const me = this;

    me.client.on(ClientEvent.LocalStreamPublished, evt => {
      me.published = true;
      me.socketConnectionLost = false;
      console.log('Publish local stream successfully');
    });

    me.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'SOCKET_DISCONNECTED') {
        me.socketConnectionLost = true;
      }
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        me.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    me.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      me.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    me.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = me.getRemoteIdFromStream(stream);
      if (!me.remoteStreams.has(id)) {
        me.remoteStreams.set(id, {
          id,
          audio: stream.isAudioOn(),
          video: stream.isVideoOn(),
          name: id, // TODO: Add Name
          stream
        });
        setTimeout(() => {
          stream.play(id);
        }, 1000);
      }
    });

    me.client.on(ClientEvent.RemoveVideoMuted, evt => {
      const id = me.getRemoteId(evt.uid);
      if (id && me.remoteStreams.has(id)) {
        me.remoteStreams.get(id).video = false;
      }
    });

    me.client.on(ClientEvent.RemoteVideoUnmuted, evt => {
      const id = me.getRemoteId(evt.uid);
      if (id && me.remoteStreams.has(id)) {
        me.remoteStreams.get(id).video = true;
      }
    });

    me.client.on(ClientEvent.RemoteAudioMuted, evt => {
      const id = me.getRemoteId(evt.uid);
      if (id && me.remoteStreams.has(id)) {
        me.remoteStreams.get(id).audio = false;
      }
    });

    me.client.on(ClientEvent.RemoteAudioUnmuted, evt => {
      const id = me.getRemoteId(evt.uid);
      if (id && me.remoteStreams.has(id)) {
        me.remoteStreams.get(id).audio = true;
      }
    });

    me.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        const id = me.getRemoteIdFromStream(stream);
        stream.stop();
        me.remoteStreams.delete(id);
      }
    });

    me.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        const id = me.getRemoteIdFromStream(stream);
        stream.stop();
        me.remoteStreams.delete(id);
      }
    });
  }

  private getRemoteIdFromStream(stream: Stream): string {
    return this.getRemoteId(stream.getId().toString());
  }

  private getRemoteId(streamId: string): string {
    return 'vp-' + streamId;
  }
}


