import { Store } from '../store/store';
import { Session } from './session.model';


export class SessionCreatingState extends Store.AbstractLoadingState<Session> {}
export class SessionCreatingErrorState extends Store.AbstractErrorState<Session> {}
export class SessionCreatedState extends Store.AbstractIdealState<Session> {}

export class SessionDestroyingState extends Store.AbstractLoadingState<Session> {}
export class SessionDestroyingErrorState extends Store.AbstractErrorState<Session> {}
export class SessionDestroyedState extends Store.AbstractIdealState<Session> {}


