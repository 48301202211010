import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
// import { CalendarComponent } from 'ng-fullcalendar';

// import { Calendar } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import moment from 'moment';

@Component({
  selector: 'app-formly-field-calendar',
  templateUrl: './formly-field-calendar.component.html',
  styleUrls: ['./formly-field-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldCalendarComponent extends FieldType implements OnInit {

  // @ViewChild('fullcalendar') fullcalendar: CalendarComponent;

  // calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  // today = new Date().toISOString().slice(0,10);
  today = new Date();

  ngOnInit() {

  }

  // handleDateClick(calDate) {
  //   console.log((calDate.date).toISOString())
  // }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    plugins: [dayGridPlugin, interactionPlugin, timeGrigPlugin],
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    selectable: true,
    unselectAuto: false,
    // selectAllow: function(select) {
    //   return moment().diff(select.start, 'days') <= 0
    // }
  //   validRange: {
  //     start: this.today
  //  }
    validRange: {
        start: (this.today).setDate((this.today).getDate() + 1),
      }
  };

  handleDateClick(arg) {
    // alert('date click! ' + arg.dateStr);
    console.log("date click!", arg.dateStr);
    this.value = arg.dateStr;
    // localStorage.setItem('selectedDate',arg.dateStr);
    // document.querySelectorAll("td[data-date='" + arg.dateStr + "']").forEach((nodeElement: HTMLElement) => {
    //   // if(nodeElement.classList.value.includes('fc-day-top')){
    //     nodeElement.classList.add('fc-highlight');
    //   // }
    // })
  }
}
