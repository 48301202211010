import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule, Routes } from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInput, MatInputModule } from '@angular/material/input';

const routes: Routes = [
  {
    path: 'header',
    component: HeaderComponent
  }
];

const imports = [
  CommonModule,
  MatToolbarModule,
  FlexLayoutModule,
  MatButtonModule,
  MatToolbarModule
];

const components = [
  HeaderComponent
];

@NgModule({
  declarations: [components],
  imports: [
    imports,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
    components
  ]
})
export class HeaderModule { }
