import { NgModule } from '@angular/core';
import { BookSlotComponent } from './book-slot.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DemoBookedModule } from 'src/app/shared/demo-booked/demo-booked.module';
import { FormlyFieldCalendarModule } from 'src/app/shared/formly-fields/calendar/formly-field-calendar.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

const components = [BookSlotComponent];
const imports = [
  CommonModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  FormlyMatDatepickerModule,
  FormlyMaterialModule,
  FormlyModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  DemoBookedModule,
  FormlyFieldCalendarModule,
  PipesModule
];
const routes: Routes = [
  {
    path: 'book-slot',
    component: BookSlotComponent,
  },
];
@NgModule({
  declarations: [components],
  imports: [imports, RouterModule.forChild(routes)],
  exports: [RouterModule, components],
})
export class BookSlotModule {}
