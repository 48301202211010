import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meeting-cancel-demo-dialog',
  templateUrl: './meeting-cancel-demo-dialog.component.html',
  styleUrls: ['./meeting-cancel-demo-dialog.component.scss']
})
export class MeetingCancelDemoDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<MeetingCancelDemoDialogComponent>,
    private navigator: Router,
    @Inject(MAT_DIALOG_DATA) private parentData: any,
  ) { }

  dialogClose(): void {
    const me = this;
    me.dialogRef.close();
  }

  navigateTO(): void {
    const me = this;
    const meetingID = me.parentData?.meetingID;
    me.navigator.navigate([`/meeting/${meetingID}/cancel`]); // re-directing to cancel feedback page
    me.dialogRef.close();
  }

}
