<div fxFlex="100" fxLayout="row" fxLayoutAlign="start" class="container">
  <mat-card class="chat-card">
    <div fxLayout="column">
      <div class="chat-card-title" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="chat-icon">
          <i class="icons8-sms"></i>
        </div>
        <div>
          <h4>Chat</h4>
        </div>
        <div class="cancel-chat" (click)="onClose()">
          <i class="icons8-multiply"></i>
        </div>
      </div>
      <div #scrollMe class="chat-msg-area">
        <div *ngFor="let message of meetingClient.chat.messages">
          <div fxLayout="column" fxLayoutAlign="start start" class="chat-msg">
            <div class="sender-name">{{message.senderName}}</div>
            <div class="msg-content">{{message.msg}}</div>
            <!--  {{timestamp}} -->
          </div>
        </div>
      </div>
      <div fxLayout="column" class="chat-input">
        <div class="canned-msg">
          <mat-chip-list>
            <mat-chip *ngFor="let message of meetingClient.chat.cannedMessages"
              (click)="meetingClient.sendChatMessage(message.msg)" class="canned-msg-chip">{{message.msg}}</mat-chip>
          </mat-chip-list>
        </div>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
          <form [formGroup]="form" fxFlex="90">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          </form>
          <div class="send-message" fxLayout="column" fxLayoutAlign="center center">
            <button mat-icon-button [disabled]="!model.Textarea"
              (click)="meetingClient.sendChatMessage(model.Textarea);options.resetModel()">
              <!-- <i class="icons8-ice-cream"></i> -->
              <img src="./assets/custom/Path 2215.svg">
            </button>
          </div>
        </div>
        <div class="vuport-footer" fxLayout="row" fxLayoutAlign="center center">
          <img src="{{sessionService.site.config.footerImg}}" alt="vuport footer">
        </div>
      </div>
    </div>
  </mat-card>
</div>