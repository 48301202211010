<div *ngIf="!meetingClient">
  <div>
    <div class="page-container" fxLayout="row" fxLayoutAlign="start">
      <div fxFlex.gt-sm="40%" fxFlex.gt-xs="100%">
        <div fxLayout="column" class="content">
          <div class="logo" fxLayout="row" fxLayoutAlign="center center">
            <img src={{sessionService.site.config.logo}} alt="Vuport logo">
          </div>
          <div class="content-title" fxLayout="row" fxLayoutAlign="center center">
            <!-- <h4>Getting things ready for you...</h4> -->
            <h4>Assigning a representative...</h4>
          </div>
          <div class="publisher-div-main" fxLayout="row" fxLayoutAlign="center stretch">
            <div class="publisher-div" #publisherDiv></div>
            <div class="publisher-controls" fxLayout="row" fxLayoutAlign="center center">
              <div class="audio-button">
                <button mat-mini-fab color="primary" (click)="toggleAudio(true)" *ngIf="!allowAudio">
                  <i class="icons8-mute-unmute"></i>
                </button>
                <button mat-mini-fab color="secondary" (click)="toggleAudio(false)" *ngIf="allowAudio">
                  <i class="icons8-microphone"></i>
                </button>
              </div>
              <div class="video-button">
                <button mat-mini-fab color="primary" (click)="toggleVideo(false)" *ngIf="!allowVideo">
                  <i class="icons8-no-video"></i>
                </button>
                <button mat-mini-fab color="secondary" (click)="toggleVideo(true)" *ngIf="allowVideo">
                  <i class="icons8-video-call"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="app-loader" fxLayout="row" fxLayoutAlign="center">
            <app-loader></app-loader>
          </div>
        </div>
      </div>
      <div fxFlex.gt-sm="60%" fxHide.xs class="productInfo">
        <div class="banner" [ngStyle]="{'background-image': 'url(' + sessionService.site.config.productInfo + ')'}">
        </div>
        <!-- <app-product-display> *ngIf="empty"></app-product-display> -->
      </div>
    </div>
  </div>
</div>
<div #meetingClientContainer></div>
<router-outlet></router-outlet>