import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { SessionService } from '../session/session.service';
import { SnackBarService } from 'src/app/shared/common/services/snack-bar.service';
import { Router } from '@angular/router';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    public snackBarService: SnackBarService,
    private navigator: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        console.log(err.error.statusCode);
        if (err.error.statusCode == 401) {
          const me = this;
          localStorage.removeItem('account');
          me.navigator.navigate(['/admin/login']);
        }

        console.log(err);
        const error = err;
        return throwError(error);
      })
    );
  }
}
