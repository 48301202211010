import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoadingModule } from './loading/loading.module';
import { HomeModule } from './home/home.module';
import { ErrorModule } from './error/error.module';
import { CreateMeetModule } from './create-meet/create-meet.module';
import { MeetingModule } from './meeting/meeting.module';
import { FormlyFieldCountryCodeSelectComponent } from '../shared/formly-fields/country-code-select/formly-field-country-code-select.component';
import { FormlyFieldNgxMatCountryComponent } from '../shared/formly-fields/ngx-mat-country/formly-field-ngx-mat-country.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'loading',
    loadChildren: () =>
      import('./loading/loading.module').then((m) => m.LoadingModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  // {
  //   path: 'book-slot',
  //   loadChildren: () =>
  //     import('./home/book-slot/book-slot.module').then((m) => m.BookSlotModule),
  // },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'create-meet',
    loadChildren: () =>
      import('./create-meet/create-meet.module').then(
        (m) => m.CreateMeetModule
      ),
  },
  {
    path: 'meeting',
    loadChildren: () =>
      import('./meeting/meeting.module').then((m) => m.MeetingModule),
  },
  {
    path: 'meeting/:id',
    loadChildren: () =>
      import('./meeting/meeting.module').then((m) => m.MeetingModule),
  },
  {
    path: 'meeting/:id/feedback',
    loadChildren: () =>
      import('./meeting/meeting-feedback/meeting-feedback.module').then(
        (m) => m.MeetingFeedbackModule
      ),
  },
  {
    path: 'meeting/:id/cancel',
    loadChildren: () =>
      import('./meeting/feedback/feedback.module').then(
        (m) => m.FeedbackModule
      ),
  },
  {
    path: 'phone',
    component: FormlyFieldNgxMatCountryComponent,
  },
  {
    path: 'country-phone',
    loadChildren: () =>
      import('../shared/formly-fields/country-phone/country-phone.module').then(
        (m) => m.CountryPhoneModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      // import('./admin/auth/login/login.module').then((m) => m.LoginModule),
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
];

const modules = [
  LoadingModule,
  HomeModule,
  ErrorModule,
  CreateMeetModule,
  MeetingModule,
];

@NgModule({
  declarations: [],
  imports: [modules, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class PagesModule {}
