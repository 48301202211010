
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
   
    
    transform(value) {
        const sec = parseInt(value, 10); 
        let hours: any = Math.floor(sec / 3600); 
        let minutes: any = Math.floor((sec - hours * 3600) / 60); 
        let seconds : any = sec - hours * 3600 - minutes * 60;
        // if (hours < 10) {      hours = '0' + hours;    }
        if (minutes < 10) {      minutes = '0' + minutes;    }
        if (seconds < 10) {      seconds = '0' + seconds;    }
        // return hours + ':' + minutes + ':' + seconds;
        return minutes + 'm' + ' '+ seconds+'s'; 
    }
}