import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyUtility } from 'src/app/utility/formly.util';
import { Store } from 'src/app/core/store/store';
import { CreateMeetLoadPayload, ReqPayload } from './service/create-meet.model';
import { CreateMeetService } from './service/create-meet.service';
import { CreateMeetLoadedState, CreateMeetLoadingErrorState, CreateMeetLoadingState } from './service/create-meet.state';


@Component({
  selector: 'app-create-meet',
  templateUrl: './create-meet.component.html',
  styleUrls: ['./create-meet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateMeetComponent implements OnInit {

  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  constructor(
    private createmeetService: CreateMeetService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({});
  }

  // fields: FormlyFieldConfig[];
  fields: FormlyFieldConfig[] = [
    // {
    //   key: 'name',
    //   type: 'input',
    //   templateOptions: {
    //     label: 'NAME',
    //     placeholder: 'Enter Your Name',
    //     appearance: 'outline',
    //   },
    //   validators: {
    //     validation: ['name'],
    //   }
    // },
    // {
    //   key: 'email',
    //   type: 'input',
    //   templateOptions: {
    //     type: 'email',
    //     label: 'EMAIL ID',
    //     placeholder: 'Enter Your Email Id',
    //     appearance: 'outline',
    //     required: true,
    //   },
    //   validators: {
    //     validation: ['email'],
    //   }
    // },
    // {
    //   // fieldGroupClassName: 'display-flex',
    //   fieldGroup: [
    //     {
    //       // className: 'flex-2',
    //       // key: 'country_code',
    //       // type: 'field-country',
    //       // templateOptions: {
    //       //   required: true,
    //       //   size: 8,
    //       //   options: [
    //       //     { label: '+91 IN', value: '91' },
    //       //     { label: '+01 US', value: '01' },
    //       //     { label: '+44 UK', value: '44' },
    //       //   ],
    //       // },
    //     },
    //     // {
    //     //   // className: 'flex-1',
    //     //   // key: 'phone',
    //     //   // type: 'input',
    //     //   // templateOptions: {
    //     //   //   label: 'PHONE NUMBER',
    //     //   //   type: 'number',
    //     //   //   placeholder: 'Enter a Phone Number',
    //     //   //   appearance: 'outline',
    //     //   //   required: true,
    //     //   // },
    //     // },
    //   ],
    // },
    // {
    //   key: 'mobile_number',
    //   type: 'field-country-mobile',
    //   templateOptions: {
    //     label: 'PHONE NUMBER',
    //     // required: true,
    //   },
    // },
    // {
    //   key: 'name',
    //   type: 'read-only-field',
    //   templateOptions: {
    //     label: 'NAME',
    //     placeholder: 'Enter Your Name',
    //     // appearance: 'outline',
    //     value: 'Dipesh'
    //   },
    // },
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'NAME',
        placeholder: 'Enter Your Name',
        appearance: 'outline',
      },
    },
    {
      key: 'customer_contact',
      type: 'contact-field',
      templateOptions: {
        label: 'PHONE NUMBER',
        // required: true,
      },
    },
    {
      key: 'verify_contact',
      type: 'verify-otp-field',
      templateOptions: {
        label: 'PHONE NUMBER',
        // required: true,
      },
    },
    // {
    //   key: 'customer_feedback_video',
    //   type: 'field-multicheckbox',
    //   templateOptions: {
    //     label: 'Video Issues',
    //     options: [
    //       { label: 'Frozen Video', value: 'frozen_video' },
    //       { label: 'Pixelated Video', value: 'pixelated_video' },
    //       { label: 'Blurry Image', value: 'blurry_image' },
    //       { label: 'Poor Color', value: 'poor_color' },
    //       { label: 'Other', value: 'other' }
    //     ]
    //   },
    // },
    // {
    //   className: 'other-input',
    //   key: 'customer_feedback_other',
    //   type: 'input',
    //   hide: true,
    //   templateOptions: {
    //     label: 'please enter other reasons',
    //     appearance: 'outline'
    //   },
    //   lifecycle: {
    //     onInit(form: FormGroup, field?: FormlyFieldConfig): void {
    //       form.get('customer_feedback_video').valueChanges.subscribe(res => {
    //         field.hide = res.other ? false : true;
    //       });
    //     }
    //   }
    // },
    // {
    //   key: 'consent_radio',
    //   className: 'radio',
    //   type: 'radio',
    //   templateOptions: {
    //     label: 'Would you like us to get in touch with us?',
    //     placeholder: '',
    //     required: true,
    //     options: [
    //       { value: true, label: 'Yes' },
    //       { value: false, label: 'No' }
    //     ],
    //   },
    // },
    {
      key: 'product_select',
      type: 'select',
      templateOptions: {
        label: 'PRODUCT TYPE',
        appearance: 'outline',
        required: true,
        options: [
          { label: 'AudiQ8', value: 'audiq8' },
          { label: 'AudiQ9', value: 'audiq9' },
          { label: 'AudiQ10', value: 'audiq10' },
        ],
        validators: {
          validation: ['vehicle'],
        }
      },
    },
    {
      key: 'customer_agree_terms',
      type: 'single-checkbox',
      templateOptions: {
        required: true,
        label: 'I agree to the',
        callToAction: true,
      },
    },
  ];

  submit() {
    const me = this;
    if (this.form.valid) {
      console.log(JSON.stringify(me.model));
      // console.log(me.fields);
      console.log(FormlyUtility.toValue(me.model, me.fields));
      // me.load(me.model);
    }
  }

  ngOnInit(): void {
  }

  load(req: ReqPayload) {
    const me = this;

    const reqPayload: CreateMeetLoadPayload = {
      request: req,
      scheduledOn: 0
    }

    me.createmeetService.load(reqPayload).subscribe(
      (state: Store.State) => {
        if (state instanceof CreateMeetLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof CreateMeetLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: CreateMeetLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: CreateMeetLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
    console.log('hello loading')
  }

  private onLoadingError(state: CreateMeetLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
    console.log('hello loaderror')
  }

  private onLoaded(state: CreateMeetLoadedState) {
    const me = this;
    me.data = state.data;
    me.empty = !me.data.length;
    me.error = false;
    me.loading = false;
    console.log('hello loaded')
  }

}
