<mat-checkbox
      [formControl]="formControl"
      [id]="id"
      [required]="to.required"
      [formlyAttributes]="field"
      [tabindex]="to.tabindex"
      [indeterminate]="to.indeterminate && formControl.value == null"
      [color]="to.color"
      [labelPosition]="to.align || to.labelPosition"
    >
      {{ to.label }}
<span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker"></span>
<i><a *ngIf="to?.callToAction" [href]="to.url" target="_blank">Terms and Conditions</a></i>
</mat-checkbox>