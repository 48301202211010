import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { FormlyFieldProductSelectComponent } from './formly-field-product-select.component';
import { MatPseudoCheckboxModule } from '@angular/material/core';

export function ProductValidator(control: FormControl): ValidationErrors {
  return !control.value ? null : { 'product': true };
}

@NgModule({
  declarations: [FormlyFieldProductSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatPseudoCheckboxModule,

    FormlyMatFormFieldModule,
    FormlySelectModule,
    FormlyModule.forChild({
      validators: [
        { name: 'product', validation: ProductValidator },
    ],
    validationMessages: [
        { name: 'product', message: 'Select a Product type'},
        { name: 'required', message: 'Above field should not be empty'},
      ],
      types: [
        {
          name: 'field-product-select',
          component: FormlyFieldProductSelectComponent,
          wrappers: ['form-field'],
        },
        { name: 'enum', extends: 'select' },
      ],
    }),
  ],
})
export class FormlyFieldProductSelectModule { }