import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldReadOnlyComponent } from './formly-field-read-only.component';
import { CommonModule } from '@angular/common';

const formlyFieldMap = [
  {
    name: 'read-only-field',
    component: FormlyFieldReadOnlyComponent
  }
];

const components = [FormlyFieldReadOnlyComponent];

const dependencies = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule
];

const modules = [dependencies];

@NgModule({
  declarations: components,
  entryComponents: components,
  imports: [
    modules,
    FormlyModule.forRoot({ types: formlyFieldMap })
  ],
  exports: [modules, components]
})
export class FormlyFieldReadOnlyModule { }


