<div class="verify-otp-formly display-flex">
  <div class="verify-otp-form">
    <form role="form" novalidate [formGroup]="localForm">
      <formly-form [fields]="fields" [form]="localForm"></formly-form>
      <span class="otp-error" *ngIf="(verifyAction === 'wrongOtp') && otpCustomError"> Please enter valid OTP </span>
    </form>
  </div>
  <div class="otp-icon">
    <mat-icon *ngIf="verifyAction === 'wrongOtp'" style="color: red;">highlight_off</mat-icon>
    <mat-icon *ngIf="verifyAction === 'correctOtp'" style="color:green">check_circle_outline</mat-icon>
  </div>
  <div *ngIf="verifyAction && (verifyAction !== 'wrongOtp') && (verifyAction !== 'correctOtp')"
    class="verify-otp-button">
    <button [disabled]="!localFormIsValid" mat-raised-button color="primary" type="button" (click)="authenticateOTP()">
      Verify OTP
    </button>
  </div>
  <div *ngIf="!verifyAction || (verifyAction === 'wrongOtp')" class="send-otp">
    <button [disabled]="(form?.controls?.customer_contact?.status ==='INVALID')" mat-raised-button color="primary"
      type="button" (click)="generateOTP()">{{!verifyAction ? 'Send OTP' : 'Resend OTP'}}</button>
  </div>
</div>


<!-- earlier approach -->

<!-- <div class="verify-otp-formly display-flex">
  <div class="verify-otp-form">
    <form role="form" novalidate [formGroup]="localForm">
      <formly-form [fields]="fields" [form]="localForm"></formly-form>
      <span class="otp-error" *ngIf="verifyAction === 'wrongOtp'"> Please enter valid OTP </span>
    </form>
  </div>
  <div class="otp-icon">
    <mat-icon *ngIf="verifyAction === 'wrongOtp'" style="color: red;">highlight_off</mat-icon>
    <mat-icon *ngIf="!verifyAction" style="color:green">check_circle_outline</mat-icon>
  </div>
  <div *ngIf="verifyAction && (verifyAction !== 'wrongOtp')" class="verify-otp-button">
    <button [disabled]="!localFormIsValid" mat-raised-button color="primary" type="button" (click)="authenticateOTP()">
      Verify OTP
    </button>
  </div>
  <div *ngIf="verifyAction && (verifyAction !== 'wrongOtp')" class="send-otp">
    <button [disabled]="(form?.controls?.customer_contact?.status ==='INVALID')" mat-raised-button color="primary"
      type="button" (click)="generateOTP()">Send OTP</button>
  </div>
  <div *ngIf="verifyAction === 'wrongOtp'" class="resend-otp">
    <button mat-raised-button color="primary" type="button" (click)="generateOTP()">Resend OTP</button>
  </div>
</div> -->