import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { NgxAgoraModule } from 'ngx-agora';
import { SessionService } from './core/session/session.service';
import { sessionProviderFactory } from './core/session/session.module';
import { InjectorResolver } from './core/injector/injector.service';
import { ChartsModule } from 'ng2-charts';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { ErrorInterceptor } from './core/interceptors/logout.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    HttpClientModule,
    LoggerModule.forRoot(null),
    PagesModule,
    ChartsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
  ],
  providers: [
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionProviderFactory,
      deps: [InjectorResolver, SessionService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
