import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MixpanelService } from '../../common/services/mixpanel.service';
import { MeetingClientComponent } from '../meeting-client.component';
import { SessionService } from 'src/app/core/session/session.service';
@Component({
  selector: 'app-meeting-chat',
  templateUrl: './meeting-chat.component.html',
  styleUrls: ['./meeting-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingChatComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() meetingClient: MeetingClientComponent;
  @Input() drawer: MatDrawer;
  @Output() closeChatWindow = new EventEmitter();
  msgLength = 0;
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'Textarea',
      type: 'input',
      templateOptions: {
        placeholder: 'Type Message here',
        required: false,
        appearance: 'outline',
        keydown: (field, $event) => {
          const me = this;
          if ($event.keyCode === 13 && !$event.shiftKey) {
            $event.preventDefault();
            me.mixpanelService.track('Message Clicks');
            me.meetingClient.sendChatMessage(field.model.Textarea);
            me.options.resetModel();
          }
        },
      },
    },
  ];
  itemElements: any;

  timestamp: any;

  constructor(
    private mixpanelService: MixpanelService,
    public sessionService: SessionService
  ) {}

  ngOnInit(): void {
    const me = this;
    me.msgLength = me.meetingClient.chat.messages.length;
    // console.log(this.meetingClient);

    let newTime = new Date();
    let timeInterval = setInterval(() => {
      newTime = new Date();
    }, 1000);
    let timeInterval1 = setInterval(() => {
      me.timestamp = newTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }, 1000);
    
  }
  ngAfterViewChecked() {
    const me = this;
    if (this.msgLength < me.meetingClient.chat.messages.length) {
      me.scrollToBottom();
      this.msgLength = me.meetingClient.chat.messages.length;
    }
  }
  initLoad() {
    const me = this;
    me.sessionService.site.config;
  }
  onClose() {
    const me = this;
    me.closeChatWindow.emit(false);
    me.drawer.close();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
