import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldCountryCodeSelectComponent } from './formly-field-country-code-select.component';
import { FormlyModule } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select';

const formlyFieldMap = [
  {
    name: 'country-code-field',
    component: FormlyFieldCountryCodeSelectComponent
  }
];

const dependencies = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  MatInputModule,
  MatAutocompleteModule,
  MatSelectModule,
];

@NgModule({
  declarations: [FormlyFieldCountryCodeSelectComponent],
  imports:
    [
      dependencies,
      FormlyModule.forRoot({ types: formlyFieldMap })
    ],
  exports: [dependencies]
})
export class FormlyFieldCountryCodeSelectModule { }
