import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/core/session/session.service';

import { MeetingClientComponent } from '../meeting-client.component';
import ZoomVideo from '@zoom/videosdk';
import { ConnectionState } from '@zoom/videosdk';
import { MeetingConnection,MeetingEvent, MeetingLobby, MeetingMessage } from 'src/app/pages/meeting/service/meeting.model';
import { ZoomConfig } from './service/zoom-meeting.model';
import { MatDialog } from '@angular/material/dialog';
import { EndCallComponent } from '../../end-call/end-call.component';
import { SnackBarService } from '../../common/services/snack-bar.service';

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MeetingClientComponent,
      useExisting: ZoomMeetingComponent,
    },
  ],
})
export class ZoomMeetingComponent extends MeetingClientComponent
implements OnDestroy {
  @ViewChild('my-video') publisherDiv: ElementRef;
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  public meetConfig: any = {};
  public signature: any;
  public stream: any;
  public client: any;
  hostLeaveMeeting: boolean = false;
  subscriberAudio: boolean;
  allowVideo: boolean;
  allowAudio: boolean;
  published: boolean;
  connected: boolean;
  messageLength: number = 0;
  drawerValue: boolean;
  recordingFlag: boolean;

  private setDefaults() {
    const me = this;
    const initAudioVideoValue = JSON.parse(
      sessionStorage.getItem('audioVideoValue')
    );
    // me.allowVideo = initAudioVideoValue?.videoValue;
    // me.allowAudio = initAudioVideoValue?.audioValue;

    me.subscriberAudio = false;
    me.published = false;
    me.connected = false;
    me.drawerValue = true;
    me.allowAudio = true;
    me.allowVideo = true;
  }

  constructor(
    public sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    private zone: NgZone,
    public dialog: MatDialog,
    public snackBarService: SnackBarService
  ) { 
    super(sessionService, router, route);
  }

  public init(
    meeting: MeetingLobby,
    meetingConnection: MeetingConnection
  ): boolean {
    const me = this;
    const config: ZoomConfig = meeting?.config?.zoom;
    console.log(`Zoom comp init---------->`)
    console.log(config)
    
    if (super.init(meeting, meetingConnection)) {

    
      me.client = ZoomVideo.createClient();
      me.client.init('en-US', 'Global');
      me.client.join(
        config.topic,
        config.signature,
        config.userName,
      ).then(() => {
        console.log('Join meeting success');
        me.stream = me.client.getMediaStream();
        setTimeout(() => {
          me.startZoomVideo(); 
        }, 1000);
        me.stream.startAudio().then(() => {
          console.log('join audio');
          me.subscriberAudio = true;
          me.connected = true;
        });

        me.startPartVideo()

      }).catch((error) => {
        console.log('Join meeting Failure');
        console.error(error);
      });
      console.log(`Client Info---------->`)
      console.log(me.client)

      me.client.on('connection-change', (payload) => {
        if (payload.state === ConnectionState.Connected) {
          // handle connected
          me.stream = me.client.getMediaStream();
          me.hostLeaveMeeting = false;
          // this.startVideo(me.stream, client)
        } else if(payload.state === ConnectionState.Reconnecting){
          // handle failover
        }
      });
    }
    return false;
  }


  ngOnDestroy(): void {
    this.toggleVideo(false);
    this.toggleAudio(false);
  }

  async startPartVideo(){
    console.log('inside startPartVideo')
    const me = this;
    const canvas = document.querySelector('#participent-video') as HTMLVideoElement;
    const canvasWidth = 1280;
    const canvasHeight = 660;
    const xOffset = 0;
    const yOffset = 0;
    const videoQuality = 2; 
    // let w: any = window.innerWidth;
    // let h: any = window.innerHeight;
    // canvas.setAttribute('width', w);
    // canvas.setAttribute('height', h)

    me.client.on('peer-video-state-change', async (payload) => {
      const { action, userId } = payload;
      if (action === 'Start') {
        console.log('start-------')
        await me.stream.renderVideo(canvas, userId, canvasWidth, canvasHeight, xOffset, yOffset, videoQuality);
      } else if (action === 'Stop') {
        await me.stream.stopRenderVideo(canvas);
      }
    });
  }
  
  ngOnInit() {
    const me = this;
    console.log(`Zoom comp ngOnInit---------->`)
    me.setDefaults();
    
  }

  ngAfterViewInit(){
    console.log('In ngAfterViewInit')
    const me = this;
    me.client.on('connection-change', (payload) => {
      if (payload.state === ConnectionState.Connected) {
        // handle connected
       
        // this.startVideo(me.stream, client)
      } else if(payload.state === ConnectionState.Reconnecting){
        // handle failover
      }
    });

    history.pushState(null, null, location.href); // browser back button action disabled
    window.onpopstate = function () {
      history.go(1);
    };

    me.meetingConnection
      .on(MeetingEvent.StartRecording)
      .subscribe((value: MeetingMessage) => {
        console.log(value);

        if (value.type === '3011') {
          me.recordingFlag = true;
        }
      });
    me.meetingConnection
      .on(MeetingEvent.StopRecording)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3012') {
          me.recordingFlag = false;
        }
      });
      me.meetingConnection
      .on(MeetingEvent.PingPong)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3041') {
          // console.log('Pong');
          me.meetingConnection.sendPong("Pong");
        }
      });
      me.meetingConnection
      .on(MeetingEvent.BatteryLevel)
      .subscribe((value: MeetingMessage) => {
        console.log(value);

        if (value.type === '3040') {
          me.snackBarService.getSnackbar(`${this.meeting.hostObject.name}'s phone battery is critically low`);
        }
      });

  }

  show: boolean = false;

  async startZoomVideo() {
    const me = this;
  
    const canvas = document.querySelector('#my-video') as HTMLVideoElement;
    const canvasWidth = 220;
    const canvasHeight = 126;
    const xOffset = 0;
    const yOffset = 0;
    const videoQuality = 2;   // equivalent to 360p; refer to the API reference for more info
  
    if (!me.stream.isCapturingVideo()) {
      console.log('In IF')
      console.log(canvas);
      console.log(typeof SharedArrayBuffer === 'function');
      try {
        // console.log(me.stream.startVideo());
        await me.stream.startVideo();
        console.log("Here");
        const session = me.client.getSessionInfo();
        await me.stream.renderVideo(canvas, session.userId, canvasWidth, canvasHeight, xOffset, yOffset, videoQuality);
        
        console.log("Here after Render Video");
        me.show = true;
       
      } catch (error) {
        console.log(error);
      }
    }
  
  }

  async stopVideo() {

    const me = this;
  
    const canvas = await document.querySelector('#my-video') as HTMLVideoElement;
    const canvasWidth = 220;
    const canvasHeight = 126;
    const xOffset = 0;
    const yOffset = 0;
    const videoQuality = 2;   // equivalent to 360p; refer to the API reference for more info
  
    if (me.stream.isCapturingVideo()) {
      try {
        await me.stream.stopVideo();
  
        const session = me.client.getSessionInfo();
        me.stream.stopRenderVideo(canvas, session.userId);

        
      } catch (error) {
        console.log(error);
      }
    }

    
  }

  public toggleAudio(value: boolean): void {
    const me = this;

    if (value === true) {
      me.allowAudio = true;
      me.stream.startAudio().then(() => console.log('join audio'));
    } else {
      me.allowAudio = false;
      me.stream.stopAudio().then(() => console.log('leave audio'));
    }
  }

  public toggleVideo(value: boolean): void {
    const me = this;
    if (value === true) {
      me.allowVideo = true;
      me.startZoomVideo()
    } else {
      me.allowVideo = false;
      me.stopVideo()
    }
  }

  tooltipMessage(): number {
    const me = this;
    let message: number;
    me.newMessages = 0;
    return (message = me.chat.messages.length);
  }

  drawerToggle(drawer) {
    const me = this;
    me.drawerValue = !me.drawerValue;
    drawer.toggle();
    console.log(me.tooltipContent);
  }
  onCloseChat(event) {
    const me = this;
    me.drawerValue = !me.drawerValue;
    me.newMessages = 0;
  }

  onEndCall() {
    const me = this;
    let dialogRef = me.dialog.open(EndCallComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        me.leaveCall();
      }
    });
  }

  public leaveCall(): void {
    const me = this;
    me.published = false;
    me.connected = false;
    me.client.leave();
    me.zone.run(() => {
      me.router.navigate(['.', 'feedback'], { relativeTo: me.route });
    });
  }


  

}
