import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from 'src/app/core/session/session.service';
import { MixpanelService } from '../common/services/mixpanel.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  site: any;

  constructor(
    private sessionService: SessionService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit(): void {
    const me = this;
    me.loadInit();
  }

  loadInit() {
    const me = this;
    me.site = me.sessionService.site;
  }

  trackMe() {
    const me = this;
    me.mixpanelService.track('FAQ Clicks');
  }

}
