import { Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AppError } from 'src/app/core/error/error.model';
import { MeetingClientComponent } from 'src/app/shared/meeting-client/meeting-client.component';
import { TokboxConfig } from 'src/app/shared/meeting-client/tokbox-meeting/service/tokbox-meeting-client.model';
import { ZoomMeetingConfig } from 'src/app/shared/meeting-client/zoom-meeting-sdk/service/zoom-meeting-sdk.model';
import { ZoomConfig } from 'src/app/shared/meeting-client/zoom-meeting/service/zoom-meeting.model';

export interface MeetingJoinPayload {
  id: string;
  first: boolean;
}

export interface MeetingClientLoadPayload {
  id: string;
}

/// ---------
// Vuport - Operations
// Prospect will connect to the MeetingConnection(Meeting.ID);
// Prospect > MeetingConnection.sendMessage(ParticipantWaitingToJoin);
// API > will get this message and it will notify to concerned devices
// Host > will connect to the MeetingConnection(Meeting.ID);
// Host > MeetingConnection.sendMessage(ParticipantJoin);
// Prospect > receive ParticipantJoin
// # Prospect > Requests for Agora Details
// # API > will give agora
// # Prospect > will join agora meeting
// Prospect > MeetingConnection.sendMessage(ParticipantJoin);
// Prospect > MeetingConnection.sendMessage(ParticipantStreamAudioToggled);
// Agora - Calling

export enum MeetingEvent {
  OnMessage = '1001',
  OnConnect = '1002',
  OnDisconnect = '1003',
  OnError = '1004',

  ParticipantWaitingToJoin = '2001',
  ParticipantJoin = '2002',
  ParticipantChatMessage = '2021',
  MeetingEndedByHost = '2011',
  ParticipantStreamMediaAccessNotAllowedError = '4001',
  StartRecording = '3011',
  StopRecording = '3012',
  BatteryLevel = '3040',
  PingPong = '3041',

  // QR display screen :  HOST_JOINING_FROM_GLASSES - 3004
  // Successful joining via glasses : HOST_JOINED_FROM_GLASSES -  3001

  // Glasses to Phone :-
  // HOST_JOINING_FROM_PHONE - 3005
  // DISCONNECT_VIA_GLASSES - 4001

  hostJoinedFromPhone = '3005',
  hostJoinedFromGlasses = '3001',
}

export interface MeetingMessage {
  id: string; // Meeting ID
  type: MeetingEvent; // Meeting Event Type
  source?: string; // Connection ID
  destination?: string; // Connection ID
  data?: any; // Data
  error?: AppError; // Error if Event Type is Error
  isMine?: boolean;
}

export interface MeetingEventPayload {
  client: MeetingClientComponent;
  data?: any;
  error?: AppError;
}

export class MeetingClientLocalStreamMediaAccessNotAllowedError extends AppError {
  constructor(missingMedia: string[]) {
    super(
      'Access to ' + missingMedia.join(' ') + ' is required.',
      'ParticipantStreamMediaAccessNotAllowedError'
    );
  }
}

export interface MeetingLobby {
  id: string;
  error: AppError;
  host: {
    name: string;
  };
  hostObject: {
    id: number;
    name: string;
  };
  config: {
    agora: {
      appId: string;
      token: string;
      channel: string;
      uid: string;
      role: string;
    };
    tokbox?: TokboxConfig;
    zoom?: ZoomConfig;
    ZoomMeetingSDK?: ZoomMeetingConfig;
  };
  recordings: [string];
  feedback: {};
  request: {};
  firstOnlineOn: 0;
  lastOnlineOn: 0;
  scheduledOn: 0;
  connect?: boolean;
  meeting_type: string;
}

export interface MeetingChatMessage {
  msg: string;
  senderName: string;
}

export interface MeetingChatCannedMessage {
  msg: string;
}

export interface MeetingChat {
  messages: MeetingChatMessage[];
  cannedMessages: MeetingChatCannedMessage[];
}

export class MeetingConnection {
  private socket: WebSocketSubject<MeetingMessage>;
  private forceClosed: boolean;
  private events: Map<MeetingEvent, Subject<MeetingMessage>> = new Map<
    MeetingEvent,
    Subject<MeetingMessage>
  >();

  constructor(private url: string, private meetingId: string) {
    this.url +=
      '?meetingId=' +
      this.meetingId +
      '&connectionType=call' +
      '&userType=prospect';
    this.connect();
  }

  public connect(): void {
    const me = this;

    me.socket = webSocket({ url: me.url });

    me.socket.subscribe(
      (message: MeetingMessage) => {
        me.events.get(MeetingEvent.OnMessage).next(message);

        if (me.events.has(message.type)) {
          me.events.get(message.type).next(message);
        }
      },
      (err: any) => {
        me.events.get(MeetingEvent.OnError).next(null);
      },
      () => {
        me.events.get(MeetingEvent.OnDisconnect).next(null);
      }
    );

    for (const e in MeetingEvent) {
      if (e) {
        this.events.set(MeetingEvent[e], new Subject<MeetingMessage>());
      }
    }
  }

  public getMessageTemplate(type: MeetingEvent): MeetingMessage {
    const me = this;
    return {
      id: me.meetingId,
      type,
    };
  }

  public sendMessageFrom(
    type: MeetingEvent,
    data?: any,
    error?: AppError
  ): void {
    const me = this;
    const message: MeetingMessage = me.getMessageTemplate(type);
    if (data) {
      message.data = data;
    }
    if (error) {
      message.error = error;
    }
    me.sendMessage(message);
  }

  public sendPong(message): void {
    const me = this;
    me.socket.next(message);
  }

  public sendMessage(message: MeetingMessage): void {
    const me = this;
    me.socket.next(message);
  }

  public sendChatMessage(data: MeetingChatMessage): void {
    const me = this;
    const message: MeetingMessage = me.getMessageTemplate(
      MeetingEvent.ParticipantChatMessage
    );
    message.data = data;
    me.sendMessage(message);
  }

  public disconnect(): void {
    console.log('Socket disconnect called-------');

    const me = this;
    me.socket.complete();
  }

  public isConnected(): boolean {
    return this.socket.closed;
  }

  public on(meetingEvent: MeetingEvent): Subject<MeetingMessage> {
    const me = this;
    return me.events.get(meetingEvent);
  }
}
