

// tslint:disable-next-line: no-namespace
export namespace FormlyUtility {

    // TODO: add data types
    export function toValue(value, fields) {
        const converted = [];
        const fieldGroupConvert = [];
        const modelValues = [];
        let avlOptions = [];
        let fldGrp = [];
        const bufferFieldGroup = [];
        const tempOut = [];
        modelValues.push(value);

        for (const fieldValue of fields) {
            const temp = {
                key: null,
                values: [],
                label: null,
                type: null,
                // meta: {}
            };
            if (fieldValue.fieldGroup) {
                fldGrp = fieldValue.fieldGroup;
                console.log('fieldGroup', fldGrp);

                for (const fldBuffer of fldGrp) {
                    if (fldBuffer.type !== 'read-only-field') {
                        bufferFieldGroup.push(fldBuffer);
                    }
                }

            }
            else {
                temp.key = fieldValue.key;
                temp.label = fieldValue.templateOptions.label;
                temp.type = fieldValue.type;

                for (let { } of modelValues) {
                    for (const property in value) {
                        if (fieldValue.key === `${property}`) {
                            if (fieldValue.templateOptions.options) {
                                if (fieldValue.type !== 'field-product-select') {
                                    for (const mdVal of modelValues) {
                                        temp.values.push(Object.entries(mdVal[fieldValue.key]));
                                    }
                                }
                                if (fieldValue.type !== 'field-name') {
                                    for (const mdVal of modelValues) {
                                        temp.values.push(Object.entries(mdVal[fieldValue.key]));
                                    }
                                }
                                avlOptions = fieldValue.templateOptions.options;
                                for (const selectedOptions of avlOptions) {
                                    if (selectedOptions.value === `${value[property]}`) {
                                        temp.values.push(selectedOptions.value);
                                        temp.values.push(selectedOptions.label);
                                    }
                                }
                            }
                            else {
                                if (fieldValue.type === 'single-checkbox') {
                                    if (`${value[property]}`) {
                                        temp.values.push([true, `${property}`]);
                                    }
                                    // else{
                                    //     temp.values.push([false, `${property}`]);
                                    // }
                                }
                                else {
                                    temp.values.push(`${value[property]}`);
                                    temp.values.push(`${value[property]}`);
                                }
                            }
                        }
                    }
                }
            }
            // converted.push(temp);
            fieldGroupConvert.push(temp);
        }

        if (bufferFieldGroup) {
            for (const fld of bufferFieldGroup) {
                // console.log('fld', fld);
                const tempInner = {
                    key: null,
                    values: [],
                    label: null,
                    type: null,
                    // meta: {}
                };
                tempInner.key = fld.key;
                tempInner.label = fld.templateOptions.label;
                tempInner.type = fld.type;

                // tslint:disable-next-line: forin
                for (const property in value) {
                    for (const mdVal of modelValues) {
                        if (fld.key === `${property}` && mdVal[fld.key] !== null) {
                            if (fld.type === 'rating-field') {
                                tempInner.values.push(Object.values(mdVal[fld.key]));
                            } else {
                                tempInner.values.push(Object.entries(mdVal[fld.key]));
                            }
                        }
                    }
                }
                tempOut.push(tempInner);
            }
        }

        for (let i = 0; i < fieldGroupConvert.length; i++) {
            if (fieldGroupConvert[i].key === null) {
                fieldGroupConvert.splice(i, 1);
                break;
            }
        }
        for (const tmpFld of tempOut) {
            console.log(tmpFld);
            fieldGroupConvert.push(tmpFld);
        }

        if (fieldGroupConvert) {
            console.log(fieldGroupConvert);
            return fieldGroupConvert;
        }
        else {
            return converted;
        }
    }

    export function parseValue() {
        // TODO: return formly compatible value
    }

}
