import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from 'src/app/core/store/store';
import { CreateMeet, CreateMeetLoadPayload, ReqPayload } from './create-meet.model';
import { CreateMeetLoadingState, CreateMeetLoadedState, CreateMeetLoadingErrorState } from './create-meet.state';
import { CREATEMEET } from './create-meet.data';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root',
})
export class CreateMeetService extends Store.AbstractService {

  load(payload: CreateMeetLoadPayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new CreateMeetLoadingState());
    }, 0);

    /// DEV CODE ----------------->

    // setTimeout(() => {
    //   output.next(new CreateMeetLoadedState(CREATEMEET));
    //   output.complete();
    // }, 2000);

    // setTimeout(() => {
    //   output.error(new CreateMeetLoadingErrorState(new Error()));
    // }, 2000);

    /// <----------------- DEV CODE

    const path = environment.api.meeting.load.endpoint;

    me.controller.post(path, payload).subscribe(
      (data: CreateMeet) => {
        output.next(new CreateMeetLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new CreateMeetLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }
}
