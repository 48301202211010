import { Component, Injector } from '@angular/core';
import { InjectorResolver } from './core/injector/injector.service';
import { SessionService } from './core/session/session.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // static i: AppComponent;

  title = 'vuport';
  // private sessionService: SessionService;

  constructor() {
    // const me = this;

    // InjectorResolver.construct(injector);

    window.moment = moment;

    // setTimeout(() => {
    //   me.sessionService = InjectorResolver.get(SessionService);
    // });

  }
}
