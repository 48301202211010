import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { FormlyFieldCountryCodeComponent } from './formly-field-country-code.component';
import { MatPseudoCheckboxModule } from '@angular/material/core';

// export function CountryValidator(control: FormControl): ValidationErrors {
//   return !control.value ? null : { 'country': true };
// }

@NgModule({
  declarations: [FormlyFieldCountryCodeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatPseudoCheckboxModule,

    FormlyMatFormFieldModule,
    FormlySelectModule,
    FormlyModule.forChild({
    //   validators: [
    //     { name: 'country', validation: CountryValidator },
    // ],
    // validationMessages: [
    //     { name: 'country', message: 'Select a country code'},
    //     { name: 'required', message: 'Above field should not be empty'},
    //   ],
      types: [
        {
          name: 'field-country',
          component: FormlyFieldCountryCodeComponent,
          wrappers: ['form-field'],
        },
        { name: 'enum', extends: 'select' },
      ],
    }),
  ],
})
export class FormlyFieldCountryCodeModule { }