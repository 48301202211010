import { Component, ChangeDetectionStrategy, ViewChild, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-formly-field-checkbox',
  templateUrl: './formly-field-checkbox.component.html',
  styleUrls: ['./formly-field-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormlyFieldCheckboxComponent extends FieldType {
  @ViewChild(MatCheckbox, { static: true }) checkbox!: MatCheckbox;
  defaultOptions = {
    templateOptions: {
      hideFieldUnderline: true,
      hideLabel: true,
      floatLabel: 'always',
      //indeterminate: true,
      color: 'primary',
      align: 'start', // start or end
      // color: 'accent', // workaround for https://github.com/angular/components/issues/18465
    },
  };

  onContainerClick(event: MouseEvent): void {
    this.checkbox.focus();
    super.onContainerClick(event);
  }
}