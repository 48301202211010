
import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';


import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Subscription } from 'rxjs';

import { AppService } from './country-mobile.component.service';
import { ICountry } from './country-mobile.component.interface';

@Component({
  selector: 'app-country-mobile',
  templateUrl: './country-mobile.component.html',
  styleUrls: ['./country-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CountryMobileComponent extends FieldType implements OnInit, OnDestroy {

  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      label: 'PHONE NUMBER',
      // type: 'number',
      // placeholder: 'Enter a Phone Number',
      // appearance: 'outline',
       hideFieldUnderline: true,
      // hideLabel: true,
      // floatLabel: 'always',
      // required: true,
    },
    // validators: {
    //   validation: ['phone'],
    // }
  };

  get type() {
    return this.to.type || 'text';
  }

  selectedCountry: any = 'IN';
  selectedPhoneNumber: any;
  countries: any[];
	register: FormGroup;
  subscription: Subscription;
  resend = false;

  constructor(private fb: FormBuilder, private appService: AppService){
    super();
  }

  ngOnInit(): void {
    this.fetchCountryList();
    this.initForm();
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  private fetchCountryList(): void {
    this.subscription = this.appService.getCountries().subscribe((res: ICountry[]) => {
			this.countries = res;
		}, error => error);
  }

  private initForm(): void{
    this.register = this.fb.group({
			phone: ['', [Validators.required, this._validatePhoneNumberInput.bind(this)]]
		});
  }

  _validatePhoneNumberInput(c: AbstractControl): object {
    let inputValue: string = c.value.toString();
    let phoneNumber: any = parsePhoneNumberFromString(inputValue, this.selectedCountry);
    if(phoneNumber){
      if(phoneNumber.isValid()){
        return null;
      } else {
        return {
          phoneNumber: {
            valid: false
          }
        }
      }
    } else {
      return {
        phoneNumber: {
          valid: false
        }
      }
    }
	}

  resetPhoneNumber(event: any): void {
		this.register.controls['phone'].setValue('');
	}

  formatPhoneNumber(event: any): void {
		let inputValue: any = this.register.controls['phone'].value;
		let phoneNumber: any = parsePhoneNumberFromString(inputValue, this.selectedCountry);
		if(phoneNumber){
			this.selectedPhoneNumber = phoneNumber.number;
			this.register.controls['phone'].setValue(phoneNumber.formatInternational());
		}
  }
  submit() {
    const me = this;
    console.log(me.selectedCountry + '' + me.selectedPhoneNumber);
    me.resend = true;
  }
}

