import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoBookedComponent } from './demo-booked.component';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from 'src/app/shared/material-module';

const components = [DemoBookedComponent];
@NgModule({
  declarations: [components],
  imports: [CommonModule, MatButtonModule, MaterialModule],
  exports: [components],
})
export class DemoBookedModule {}
