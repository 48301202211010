import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from 'src/app/core/store/store';
import { MeetingConnection, MeetingJoinPayload, MeetingLobby, MeetingMessage } from './meeting.model';
import { MeetingLobbyLoadingState, MeetingLobbyLoadedState, MeetingLobbyLoadingErrorState } from './meeting.state';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/core/session/session.service';




@Injectable({
  providedIn: 'root',
})
export class MeetingLobbyService extends Store.AbstractService { // TODO: rename to meeting service

  private connections: Map<string, MeetingConnection> = new Map<string, MeetingConnection>();

  constructor(private sessionService: SessionService) {
    super();
  }

  id = new Subject<any>();

  connect(id: string): MeetingConnection {
    MeetingConnection
    const me = this;
    if (!me.connections.has(id)) {
      me.connections.set(id, new MeetingConnection(me.sessionService.site.config.socketURL, id));
    }
    return me.connections.get(id);
  }

  join(payload: MeetingJoinPayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new MeetingLobbyLoadingState());
    }, 0);

    const path = environment.api.meeting.join.endpoint;

    me.controller.get(path, payload).subscribe(
      (data: MeetingLobby) => {
        output.next(new MeetingLobbyLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new MeetingLobbyLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  getMeetingStatus(payload: MeetingJoinPayload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new MeetingLobbyLoadingState());
    }, 0);

    const path = environment.api.meeting.load.endpoint;

    me.controller.get(path, payload).subscribe(
      (data: MeetingLobby) => {
        output.next(new MeetingLobbyLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new MeetingLobbyLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  updateMeeting(payload: MeetingJoinPayload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new MeetingLobbyLoadingState());
    }, 0);

    const path = environment.api.meeting.updateMeeting.endpoint+ '?id=' + payload.id;

    me.controller.put(path, payload).subscribe(
      (data: MeetingLobby) => {
        output.next(new MeetingLobbyLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new MeetingLobbyLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }
}
