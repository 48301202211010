<div class="meeting-not-available-container">
    <!-- <span (click)="cancel()" class="go-back">&times;</span> -->
    <div class="content" xLayout="column" fxLayoutAlign="center center">
        <div>
            <div>
                <p>This demo is not available</p>
            </div>
            <div>
                <button class="demo-button" (click)="onClick()"><span>Okay</span></button>
            </div>
        </div>
    </div>


</div>