<!-- <form [formGroup]="register" (ngSubmit)="submit()"> -->
    <!-- <label>Phone</label>
    <div>
      <ng-container *ngIf="countries">
        <select (change)="resetPhoneNumber($event)" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let country of countries" [ngValue]="country.code">{{country.name + " " + country.code}}</option>
        </select>
      </ng-container>
      <input type="text" (input)="formatPhoneNumber($event)" formControlName="phone">
    </div>
    <p>*******************************************************************</p> -->
    <div [formGroup]="register" (ngSubmit)="submit()">
    <div>
      <ng-container *ngIf="countries">
        <mat-form-field appearance="outline" style="width: 20%; padding: 5px;" >
          <mat-select (change)="resetPhoneNumber($event)" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let country of countries" [value]="country.code">
                {{country.code}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <mat-form-field appearance="outline" style="width: 75%; padding: 5px;">
      <input 
      (input)="formatPhoneNumber($event)" 
      formControlName="phone"
  
      matInput
      [type]="type || 'text'"
      placeholder="Enter a phone number"
      >
    </mat-form-field>
      <ng-template #numberTmp>
        <input
          matInput
          [id]="id"
          type="number"
          [readonly]="to.readonly"
          [required]="to.required"
          [errorStateMatcher]="errorStateMatcher"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [tabindex]="to.tabindex"
          [placeholder]="to.placeholder"
        />
      </ng-template>
    </div>
    <!-- <label for="verifyotp">VERIFY OTP</label> -->
    <div>
      <mat-form-field appearance="outline" style="width: 100px; padding: 5px;">
        <input       
        name="verifyotp"
        matInput
        label="VERIFY OTP"
        type="number"
        placeholder="VERIFY OTP"
        >
      </mat-form-field>
      <button mat-raised-button color="primary" [disabled]="!resend" style="margin: 5px;">Resend OTP</button>
      <button mat-raised-button color="primary" [disabled]="!register.valid" style="margin: 5px;">Send OTP</button>
    </div>
  </div>
  <!-- </form> -->