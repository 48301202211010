import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormlyModule } from '@ngx-formly/core';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';

import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { FormlyFieldEmailComponent } from './formly-field-email.component';

// export function EmailValidator(control: FormControl): ValidationErrors {
//   return !control.value ||
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
//       control.value
//     )
//     ? null
//     : { email: true };
// }
const components = [FormlyFieldEmailComponent]
@NgModule({

  declarations: [components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,

    FormlyMatFormFieldModule,
    FormlyModule.forChild({
      // validators: [{ name: 'email', validation: EmailValidator }],
      // validationMessages: [
      //   { name: 'email', message: 'Enter a valid email' },
      //   { name: 'required', message: 'Above field should not be empty' },
      // ],
      types: [
        {
          name: 'field-email',
          component: FormlyFieldEmailComponent,
          wrappers: ['form-field'],
        },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
      ],
    }),
  ],
  exports: [components]
})
export class FormlyFieldEmailModule { }
