<mat-drawer-container *ngIf="session" [hasBackdrop]="false">
    <mat-drawer #drawer mode="side" position="end">
        <app-meeting-chat [meetingClient]="this" [drawer]="drawer" (closeChatWindow)="onCloseChat($event)">
        </app-meeting-chat>
    </mat-drawer>
    <mat-drawer-content>
        <div class="meeting-container" fxLayout="column">
            <div #publisherDiv class="local">
                <!-- <div class="subscriber-audio" *ngIf="subscriberAudio">
                    <i class="icons8-microphone"></i>
                </div>
                <div class="subscriber-audio" *ngIf="!subscriberAudio">
                    <i class="icons8-mute-unmute"></i>
                </div> -->
                <!-- <button class="subscriber-audio" (click)="toggleAudio(true)" *ngIf="published && !allowAudio">
                    <i class="icons8-mute-unmute"></i>
                </button>
                <button class="subscriber-audio" (click)="toggleAudio(false)" *ngIf="published && allowAudio">
                    <i class="icons8-microphone"></i>
                </button>
                <div class="exec-name typo-base" *ngIf="meeting">
                    <h4>{{meeting.hostObject.name}}</h4>
                </div> -->
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" class="meeting-controls">
                <!-- <div *ngIf="hostJoinedByPhone">
                    <h1>SE joined through phone</h1>
                </div> -->
                <div class="seMessage" *ngIf="hostLeaveMeeting">
                    <h1>Sales Executive has left the demo</h1>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between end" fxFlex>
                    <div class="subscriber-audio" *ngIf="subscriberAudio" [ngClass]="{'left-audio': chatOpened}">
                        <i class="icons8-microphone"></i>
                    </div>
                    <div class="subscriber-audio" *ngIf="!subscriberAudio" [ngClass]="{'left-audio': chatOpened}">
                        <i class="icons8-mute-unmute"></i>
                    </div>
                    <div class="exec-name typo-base" *ngIf="meeting" [ngClass]="{'left-percent': chatOpened}">
                        <h4>{{meeting.hostObject.name}}</h4>
                    </div>
                    <div class="controls" fxLayout="row" fxLayoutAlign="center center" fxFlex>
                        <button mat-fab color="primary" (click)="toggleAudio(true)" *ngIf="published && !allowAudio">
                            <i class="icons8-mute-unmute"></i>
                            <!-- <span>Unmute</span> -->
                        </button>
                        <button mat-fab color="secondary" (click)="toggleAudio(false)" *ngIf="published && allowAudio">
                            <i class="icons8-microphone"></i>
                            <!-- <span>Mute</span> -->
                        </button>
                        <button mat-fab color="primary" (click)="toggleVideo(true)" *ngIf="published && !allowVideo">
                            <i class="icons8-no-video"></i>
                        </button>
                        <button mat-fab color="secondary" (click)="toggleVideo(false)" *ngIf="published && allowVideo">
                            <i class="icons8-video-call"></i>
                        </button>

                        <div class="toast-msgs arrow-bottom"
                            [ngClass]=" {'msg-show': msgFlag ,'msg-not-show': !msgFlag || !drawerValue }">
                            <div>
                                <div fxLayout="row" fxLayoutAlign="start none"><i class="icons8-sms"></i>
                                    <p>{{tooltipContent?.senderName}}</p>
                                </div>
                                <div class="ellipse-msg" ellipsis [ellipsis-content]="tooltipContent?.msg">
                                    {{tooltipContent?.msg}}
                                </div>
                            </div>
                        </div>
                        <button mat-fab [color]="drawerValue ? 'secondary' : 'primary' " class="chat-controls"
                            (click)="drawerToggle(drawer);tooltipMessage()"
                            [matBadge]="drawerValue? (newMessages===0) ? '':newMessages:''" matBadgePosition="after">
                            <i class="icons8-sms"></i>
                        </button>
                        <span class="controls">
                            <button mat-fab color="primary" (click)="onEndCall()" *ngIf="connected">
                                <i class="icons8-end-call"></i>
                            </button>
                        </span>
                    </div>
                    <!-- </div> -->
                    <div class="recording-indicator">
                        <div *ngIf="recordingFlag" class="circle"><span class="recording">REC</span></div>
                    </div>
                </div>
            </div>
            <div #remotes class="remotes">
                <div fxFlex="100" class="remote-player-container">
                    <div #subscriberDiv class="remote-player"></div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>