import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-field-product-select',
  templateUrl: './formly-field-product-select.component.html',
  styleUrls: ['./formly-field-product-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldProductSelectComponent extends FieldType {
  @ViewChild(MatSelect, { static: true }) formFieldControl!: MatSelect;

  defaultOptions = {
    templateOptions: {
      // label: 'PRODUCT TYPE',
      appearance: 'outline',
      minLength: 1,
      maxLength: 20,
      options: [],
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
    },
  };

  private selectAllValue!: { options: any; value: any[] };

  getSelectAllState(options: any[]) {
    if (this.empty || this.value.length === 0) {
      return '';
    }

    return this.value.length !== this.getSelectAllValue(options).length
      ? 'indeterminate'
      : 'checked';
  }

  toggleSelectAll(options: any[]) {
    const selectAllValue = this.getSelectAllValue(options);
    this.formControl.setValue(
      !this.value || this.value.length !== selectAllValue.length
        ? selectAllValue
        : []
    );
  }

  change($event: MatSelectChange) {
    if (this.to.change) {
      this.to.change(this.field, $event);
    }
  }

  _getAriaLabelledby() {
    if (this.to.attributes && this.to.attributes['aria-labelledby']) {
      return this.to.attributes['aria-labelledby'];
    }

    if (this.formField && this.formField._labelId) {
      return this.formField._labelId;
    }

    return null;
  }

  private getSelectAllValue(options: any[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      const flatOptions: any[] = [];
      options.forEach((o) =>
        o.group ? flatOptions.push(...o.group) : flatOptions.push(o)
      );

      this.selectAllValue = {
        options,
        value: flatOptions.map((o) => o.value),
      };
    }

    return this.selectAllValue.value;
  }
}
