import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-slots-unavailable',
  templateUrl: './time-slots-unavailable.component.html',
  styleUrls: ['./time-slots-unavailable.component.scss']
})
export class TimeSlotsUnavailableComponent implements OnInit {
  vuPortLogo: string;
  
  constructor(
    public dialogRef: MatDialogRef<TimeSlotsUnavailableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navigator: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(){
    const me = this;
    console.log('No slots available');
    me.navigator.navigateByUrl('/home');
    me.dialogRef.close();
  }

  // cancel(): void{
  //   const me = this;
  //   me.dialogRef.close({ getData: false });
  // }

}
