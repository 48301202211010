<div class="demo-booked-container">
    <!-- <span (click)="cancel()" class="go-back">&times;</span> -->
    <div class="content" xLayout="column" fxLayoutAlign="center center">
        <div>
            <div>
                <p>We have successfully booked your demo.</p>
                <p>Please check your email for details.</p>
            </div>
            <div>
                <button class="demo-button" (click)="onClick()"><span>Okay</span></button>
            </div>
        </div>
    </div>


</div>