import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SessionService } from 'src/app/core/session/session.service';
import { Store } from 'src/app/core/store/store';
import { MixpanelService } from 'src/app/shared/common/services/mixpanel.service';
import { FormlyUtility } from 'src/app/utility/formly.util';
import { AppLocalStorage } from 'src/app/utility/local-storage.util';
import { ReqPayload, CreateMeetLoadPayload } from './service/home.model';
import { HomeService } from './service/home.service';

import {
  CreateMeetLoadingState,
  CreateMeetLoadedState,
  CreateMeetLoadingErrorState,
} from './service/home.state';
import { MatDialog } from '@angular/material/dialog';
import { BookSlotComponent } from './book-slot/book-slot.component';
import { DemoBookedComponent } from 'src/app/shared/demo-booked/demo-booked.component';
import { SnackBarService } from 'src/app/shared/common/services/snack-bar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  dataLead: any;
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  showBookSlotFlag: boolean = false;
  formData: CreateMeetLoadPayload;
  homeFormDirective: FormGroupDirective;
  rescheduleDemo: string;
  querryData: any;
  liveClicked: boolean = false;
  termClick: number = 0;
  leadData: any = {};
  leadId: string;

  constructor(
    private homeService: HomeService,
    private router: Router,
    public sessionService: SessionService,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    const me = this;
    // this.route.queryParamMap.subscribe((params)=>{
    //   console.log({params})

    //   me.querryData = params;
    //   console.log(me.querryData.params.rescheduleDemo)
    // })
    const currentDate = new Date();
    const mixPanelMetaData = { timeStamp: currentDate };
    me.mixpanelService.track('Home Screen', mixPanelMetaData);
    me.initLoad();

    // me.rescheduleDemo = localStorage.getItem('rescheduleDemo');
    // console.log("rescheduleDemo",me.rescheduleDemo);

    // if(me.querryData.params.rescheduleDemo == 'true') {
    //   me.showBookSlotFlag = true;
    // }

    me.route.queryParams.subscribe((params) => {
      if (params.demo) {
        me.rescheduleDemo = params.demo;
        // this.route.navigate([], {
        //   queryParams: {
        //     tab: null,
        //   },
        //   queryParamsHandling: 'merge',
        // });
        if (me.rescheduleDemo == 'reschedule') {
          // me.customerDetailStatus('COMPLETED');
          me.showBookSlotFlag = true;
        }
      }
    });
  }
  // ngOnDestroy(): void {
  //   console.log("ngOnDestroy called", this.querryData.params.rescheduleDemo)
  // }
  async liveDemo(): Promise<void> {
    // liveDemo(formDirective: FormGroupDirective): void {
    const me = this;
    me.liveClicked = true;
    const formValue = me.form.value;
    localStorage.setItem('liveDemoData', JSON.stringify(formValue));

    // store data in sessionStorage to compaire it with data sending while meeting creation
    let storedLeadData = JSON.parse(sessionStorage.getItem('leadData'));
    const mixPanelMetaData = {
      firstName: formValue?.customer_firstName,
      lastName: formValue?.customer_lastName,
      EmailID: formValue?.customer_email,
      ProductType: formValue?.product,
      phoneNumber: formValue?.customer_phone,
    };
    me.mixpanelService.track('Live demo', mixPanelMetaData);
    if (me.form.status == 'VALID') {
      // JSON compaire to generate lead if storedLeadData and form data are different

      if (JSON.stringify(storedLeadData) === JSON.stringify(me.form.value)) {
        console.log('======call load method');
        me.liveClicked = false;
        me.load(FormlyUtility.toValue(me.model, me.fields));
      }
      if (JSON.stringify(storedLeadData) != JSON.stringify(me.form.value)) {
        console.log('======call newLead method');
        await me.newLead();
        // me.load(FormlyUtility.toValue(me.model, me.fields));
      }
      // else {
      //   console.log('======call generate lead meeting=======');
      //   // await me.generateLead();
      //   // await me.newLead();
      //   me.load(FormlyUtility.toValue(me.model, me.fields));
      // }
    }
  }

  submit(formDirective: FormGroupDirective): void {
    const me = this;
    me.homeFormDirective = formDirective;
    const formValue = me.form.value;
    console.log('formValue?.product', formValue?.product);
    const mixPanelMetaData = {
      firstName: formValue?.customer_firstName,
      lastName: formValue?.customer_lastName,
      EmailID: formValue?.customer_email,
      ProductType: formValue?.product,
      phoneNumber: formValue?.customer_phone,
    };
    me.mixpanelService.track('Request a demo', mixPanelMetaData);
    if (this.form.valid) {
      //me.load(FormlyUtility.toValue(me.model, me.fields));
      let time = new Date().toUTCString();
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const reqPayload: CreateMeetLoadPayload = {
        request: {
          prospectForm: FormlyUtility.toValue(me.model, me.fields),
        },
        scheduledOn: time,
        timeZone: timeZone,
        leadId: me.leadId,
      };
      // let dialogRef = me.dialog.open(SearchSlotComponent, {
      //   width: '450px',
      //   data: {
      //     dataKey: reqPayload,
      //   },
      // });
      // dialogRef.afterClosed().subscribe((result) => {
      //   if (result.IsBooked) {
      //     me.form.reset();
      //     formDirective.resetForm();
      //     console.log('dialog open');
      //   }
      // });
      me.formData = reqPayload;
      console.log('formData', me.formData);
      // me.form.reset();
      // formDirective.resetForm();
      me.showBookSlotFlag = true;
    }
  }

  slotEvents(event) {
    const me = this;
    if (event.action == 'submit') {
      me.showBookSlotFlag = false;

      setTimeout(() => {
        me.form.reset();
        me.homeFormDirective.resetForm();
      }, 500);
    }
    if (event.action == 'back') {
      me.showBookSlotFlag = false;
      me.model = me.form.value;
    }
  }

  load(req: ReqPayload[]) {
    const me = this;
    let time = new Date().toUTCString();
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const reqPayload: CreateMeetLoadPayload = {
      request: {
        prospectForm: req,
      },
      scheduledOn: time,
      timeZone: timeZone,
      leadId: me.leadId,
    };
    // me.formData = reqPayload;
    console.log(`Request Payload: ${reqPayload}`);
    me.homeService.load(reqPayload).subscribe(
      (state: Store.State) => {
        if (state instanceof CreateMeetLoadingState) {
          me.onLoading(state);
          return;
        }
        if (state instanceof CreateMeetLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: CreateMeetLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: CreateMeetLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: CreateMeetLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: CreateMeetLoadedState) {
    const me = this;
    me.data = state.data;
    me.error = false;
    me.loading = false;

    if (me.data) {
      console.log('Before Navigation');
      me.router.navigate(['/meeting', me.data.id]); // pass meeting key as the id
      me.form.reset();
      AppLocalStorage.set('USER_INFO', 'USER_INFO', me.data);
    }
  }

  initLoad() {
    const me = this;
    me.fields = me.sessionService.site.config.meetingRequestForm;
    // console.log('form.value.customer_agree_terms', me.form.value.customer_agree_terms)
  }

  ngAfterViewInit() {
    const me = this;
    me.generateLead();
  }

  generateLead() {
    const me = this;

    me.form.get('customer_agree_terms').valueChanges.subscribe((select) => {
      console.log('inside generateLead method-------');
      if (select) {
        me.form.value.customer_agree_terms = true;
        me.liveClicked = false;
      }
      console.log('form value', me.form.value);
      me.termClick = me.termClick + 1; // ensure same data wont be stored in sessionStorage
      console.log('termClick', me.termClick);

      console.log('---------click---------', select);

      if (select && me.form.valid) {
        console.log('inside ======>');
        // let storedLeadData = JSON.parse(sessionStorage.getItem('leadData'));
        if (me.termClick < 2) {
          console.log('Inside localStorage');
          me.leadData = me.form.value;
          me.leadData.customer_agree_terms = true;
          sessionStorage.setItem('leadData', JSON.stringify(me.leadData));
        }
        console.log('form value', me.form.valid);
        let time = new Date().toUTCString();
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const reqPayload: CreateMeetLoadPayload = {
          request: {
            prospectForm: FormlyUtility.toValue(me.model, me.fields),
          },
          scheduledOn: time,
          timeZone: timeZone,
          leadId: null,
        };
        console.log('reqPayload====', reqPayload);
        let storedLeadData = JSON.parse(sessionStorage.getItem('leadData'));

        if (
          me.termClick < 2 &&
          JSON.stringify(storedLeadData) == JSON.stringify(me.form.value)
        ) {
          console.log('==============Inside createLead service');

          me.homeService.createLead(reqPayload).subscribe((res) => {
            if (res) {
              me.dataLead = res;
              me.leadId = me.dataLead.id;
            }
          });
        }
        if (JSON.stringify(storedLeadData) != JSON.stringify(me.form.value)) {
          // should we replace the original (i.e. storedLeadData) stored value?
          const me = this;
          console.log(JSON.stringify(storedLeadData));
          console.log(JSON.stringify(me.form.value));
          console.log(
            JSON.stringify(storedLeadData) != JSON.stringify(me.form.value),
            ' In 2nd If'
          );
          me.newLead();
        }
        // else {
        //   console.log('Inside Else------>');
        //   const me = this;
        //   // me.newLead();
        // }
      }
    });
  }

  async newLead() {
    const me = this;
    console.log('inside newLead--------and onClick', me.termClick);
    if (me.form.valid) {
      console.log('inside ======>');
      if (me.termClick < 2) {
        console.log('Inside localStorage');
        me.leadData = me.form.value;
        me.leadData.customer_agree_terms = true;
        sessionStorage.setItem('leadData', JSON.stringify(me.leadData));
      }
      console.log('form value', me.form.valid);
      let time = new Date().toUTCString();
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const reqPayload: CreateMeetLoadPayload = {
        request: {
          prospectForm: FormlyUtility.toValue(me.model, me.fields),
        },
        scheduledOn: time,
        timeZone: timeZone,
        leadId: null,
      };
      console.log('reqPayload====', reqPayload);

      await me.homeService.createLead(reqPayload).subscribe((res) => {
        console.log('Here Again');
        if (res) {
          me.dataLead = res;
          me.leadId = me.dataLead.id;
          if (me.liveClicked) {
            console.log('----call load method from generate newLead method');
            me.load(FormlyUtility.toValue(me.model, me.fields)); //prevents automatic meeting but also prevents meeting if commented
          }
        }
      });
      // me.homeService.createLead(reqPayload).subscribe(
      //   (state: Store.State) => {
      //     if (state instanceof CreateMeetLoadingState) {
      //       me.dataLead = null;
      //       return;
      //     }
      //     if (state instanceof CreateMeetLoadedState) {
      //       me.dataLead = state.data;
      //       me.leadId = me.dataLead.id; //send this leadId in payload while requesting for meeting
      //       return;
      //     }
      //   },
      //   (state: CreateMeetLoadingErrorState) => {
      //     me.dataLead = null;
      //   }
      // );
    }
  }

  ngOnDestroy() {
    console.log('ngOnDestroy======');
    sessionStorage.clear();
  }
}
