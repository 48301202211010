import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    track(id: string, action: any = {}): void {
        const vuPortToken = '2d0260f6a0fceba0f33cc35fce53f476';
        mixpanel.init(vuPortToken);
        mixpanel.track(id, action);
    }
}
