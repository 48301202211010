import { Route } from '@angular/compiler/src/core';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppError } from 'src/app/core/error/error.model';
import { SessionService } from 'src/app/core/session/session.service';
import {
  MeetingChat,
  MeetingChatMessage,
  MeetingClientLocalStreamMediaAccessNotAllowedError,
  MeetingConnection,
  MeetingEvent,
  MeetingEventPayload,
  MeetingLobby,
  MeetingMessage,
} from 'src/app/pages/meeting/service/meeting.model';
import { IDGenerator } from 'src/app/utility/id-generator.util';
import { AppLocalStorage } from 'src/app/utility/local-storage.util';

@Component({
  selector: 'app-meeting-client',
  templateUrl: './meeting-client.component.html',
  styleUrls: ['./meeting-client.component.scss'],
})
export class MeetingClientComponent implements OnInit {
  public id: string;
  public error: AppError;
  public meeting: MeetingLobby;
  public meetingConnection: MeetingConnection;
  public newMessages = 0;
  public tooltipContent;
  public msgFlag: boolean;
  hostJoinedByPhone: boolean = false;
  hostJoinedByGlasses: boolean = false;
  public userName: string;
  public chat: MeetingChat = {
    messages: [],
    cannedMessages: [
      {
        msg: 'Yes',
      },
      {
        msg: 'Wow',
      },
      {
        msg: 'This is amazing!',
      },
      {
        msg: `I'm interested!`,
      },
    ],
  };
  _isBoolean$ = new BehaviorSubject<boolean>(false);

  constructor(
    public sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    // this.userName = JSON.parse(sessionStorage.getItem('userinfo'));
  }
  @ViewChild('tooltip') tooltip: MatTooltip;
  ngOnInit() {
    const me = this;
  }
  public init(
    meeting: MeetingLobby,
    meetingConnection: MeetingConnection
  ): boolean {
    const me = this;
    if (me.isValidConfig(meeting) && meetingConnection) {
      me.meeting = meeting;
      me.meetingConnection = meetingConnection;
      me.initMeetingConnectionListeners();
      return true;
    }
    return false;
  }

  public join(): void {
    throw new Error('Method [join] not configured.');
  }

  public leave(): void {
    throw new Error('Method [leave] not configured.');
  }

  public toggleVideo(value: boolean): void {
    throw new Error('Method [toggleVideo] not configured.');
  }

  public toggleAudio(value: boolean): void {
    throw new Error('Method [toggleAudio] not configured.');
  }

  public sendChatMessage(msg: string): void {
    const me = this;
    const userInfo = AppLocalStorage.get('USER_INFO', 'USER_INFO');
    const data: MeetingChatMessage = {
      msg,
      senderName: userInfo.name,
    };
    me.meetingConnection.sendChatMessage(data);
  }

  protected isValidConfig(meetingLobby: MeetingLobby): boolean {
    // TODO: validate meeting logbby object
    return true;
  }

  private initMeetingConnectionListeners() {
    const me = this;
    me.meetingConnection
      .on(MeetingEvent.ParticipantChatMessage)
      .subscribe((value: MeetingMessage) => {
        if (value.isMine === false) {
          me.chat.messages.push(value.data as MeetingChatMessage);
          this.newMessages++;
          if (value.data) {
            me.msgFlag = true;
            me.tooltipContent = value.data;
            setTimeout(() => {
              me.msgFlag = false;
            }, 2000);
          }
          // console.log(value.data);
        } else {
          let newMsg = value.data as MeetingChatMessage;
          newMsg.senderName = newMsg.senderName + ' (You)';
          me.chat.messages.push(newMsg);
        }
      });
      me.meetingConnection
      .on(MeetingEvent.PingPong)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3041') {
          // console.log('Pong');
          me.meetingConnection.sendPong("Pong");
        }
      });
    me.meetingConnection
      .on(MeetingEvent.hostJoinedFromPhone)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3005') {
          console.log('hostJoinedFromPhone');
          // this.hostJoinedByPhone = true;
        }
      });
    me.meetingConnection
      .on(MeetingEvent.hostJoinedFromGlasses)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3001') {
          console.log('hostJoinedFromGlasses');
          // this.hostJoinedByGlasses = true;
        }
      });
    me.meetingConnection //redirect to feedback screen
      .on(MeetingEvent.MeetingEndedByHost)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '2011') {
          me.router.navigate(['.', 'feedback'], { relativeTo: me.route });
        }
      });
    // me.meetingConnection
    //   .on(MeetingEvent.StartRecording)
    //   .subscribe((value: MeetingMessage) => {
    //     if (value.type === '3011') {
    //       this.recordingFlag = true;
    //       console.log('recording start');
    //     }
    //   });
    me.meetingConnection
      .on(MeetingEvent.ParticipantStreamMediaAccessNotAllowedError)
      .subscribe((value: MeetingMessage) => {
        me.error = new MeetingClientLocalStreamMediaAccessNotAllowedError([
          'Camera',
          'Microphone',
        ]);
      });
  }
}
