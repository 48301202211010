import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/core/session/session.service';

import { MeetingClientComponent } from '../meeting-client.component';
import ZoomVideo from '@zoom/videosdk';
import { ConnectionState } from '@zoom/videosdk';
import { MeetingConnection,MeetingEvent, MeetingLobby, MeetingMessage } from 'src/app/pages/meeting/service/meeting.model';
import { ZoomMeetingConfig } from './service/zoom-meeting-sdk.model';
import { MatDialog } from '@angular/material/dialog';
import { EndCallComponent } from '../../end-call/end-call.component';
import { SnackBarService } from '../../common/services/snack-bar.service';

@Component({
  selector: 'app-zoom-meeting-sdk',
  templateUrl: './zoom-meeting-sdk.component.html',
  styleUrls: ['./zoom-meeting-sdk.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MeetingClientComponent,
      useExisting: ZoomMeetingSdkComponent,
    },
  ],
})
export class ZoomMeetingSdkComponent extends MeetingClientComponent
 {
  
  public meetConfig: any = {};
  public signature: any;
  public stream: any;
  public client: any;
  

  constructor(
    public sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    private zone: NgZone,
    public dialog: MatDialog,
    public snackBarService: SnackBarService
  ) { 
    super(sessionService, router, route);
  }

  public init(
    meeting: MeetingLobby,
    meetingConnection: MeetingConnection
  ): boolean {
    const me = this;
    const config: ZoomMeetingConfig = meeting?.config?.ZoomMeetingSDK;
    console.log(`Zoom comp init---------->`);
    console.log(config.zoomId);
   
    // console.log(config);

    // window.location.href = 'http://localhost:4000/zoom'+'?meetingId='+ config.zoomId;

    window.location.href = 'https://d1jnm884a7ww4n.cloudfront.net/zoom'+'?meetingId='+ config.zoomId;

    // window.location.href = 'https://d1jnm884a7ww4n.cloudfront.net/zoom'+'?zoomId='+ config.zoomId+'?meetingId='+ config;
    
    return true;
  }

  
  ngOnInit() {
    const me = this;
    console.log(`Zoom comp ngOnInit---------->`)
   
  }

  
}
