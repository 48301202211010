<mat-drawer-container class="example-container" [hasBackdrop]="false">
    <mat-drawer #drawer mode="side" position="end">
        <app-meeting-chat [meetingClient]="this"></app-meeting-chat>
    </mat-drawer>
    <mat-drawer-content>
        <div *ngIf="error">
            {{error.msg}}
        </div>
        <div *ngIf="!error" fxLayout="column" class="meeting-container">

            <div [id]="localStreamElementId" class="local"></div>

            <div fxLayout="row" fxLayoutAlign="center center" class="meeting-controls">
                <div fxLayout="row" fxLayoutAlign="space-between end" fxFlex>
                    <div class="controls" fxLayout="row" fxLayoutAlign="center center" fxFlex>
                        <button mat-mini-fab color="primary" (click)="toggleAudio(true)"
                            *ngIf="published && !allowAudio">
                            <i class="icons8-mute-unmute"></i>
                        </button>
                        <button mat-mini-fab color="secondary" (click)="toggleAudio(false)"
                            *ngIf="published && allowAudio">
                            <i class="icons8-microphone"></i>
                        </button>
                        <button mat-mini-fab color="primary" (click)="leave()" *ngIf="connected">
                            <i class="icons8-call"></i>
                        </button>
                        <button mat-mini-fab color="primary" (click)="toggleVideo(true)"
                            *ngIf="published && !allowVideo">
                            <i class="icons8-no-video"></i>
                        </button>
                        <button mat-mini-fab color="secondary" (click)="toggleVideo(false)"
                            *ngIf="published && allowVideo">
                            <i class="icons8-video-call"></i>
                        </button>
                        <button mat-mini-fab color="primary" class="chat-controls" (click)="drawer.toggle()">
                            <i class="icons8-sms"></i>
                        </button>

                    </div>
                </div>
            </div>

            <div class="no-remotes typo-heading-2">
               <span *ngIf="!remoteStreams.size && !socketConnectionLost">No One Joined</span> 
               <span *ngIf="socketConnectionLost">Please check your internet connection</span>
            </div>

            <div #remotes class="remotes" *ngIf="published && connected">
                <div fxFlex="100" class="remote-player-container"
                    *ngFor="let stream of remoteStreams | keyvalue; trackBy: remoteTrackBy;">
                    <div class="remote-meta" fxLayout="row" fxLayoutAlign="start center">
                        <i class="icons8-mute-unmute" *ngIf="!stream.value.audio"></i>
                        <span class="remote-name">{{stream.value.name}}</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" class="remote-no-video typo-heading-2"
                        *ngIf="!stream.value.video">
                        <i class="icons8-no-video"></i> <span>Host has disabled the video</span>
                    </div>
                    <div class="remote-player" [ngClass]="{'no-video': !stream.value.video}" [id]="stream.value.id">
                    </div>
                </div>
            </div>

        </div>

    </mat-drawer-content>
</mat-drawer-container>