import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from 'src/app/core/store/store';
import { CreateMeet, CreateMeetLoadPayload, ReqPayload } from './home.model';
import { CreateMeetLoadingState, CreateMeetLoadedState, CreateMeetLoadingErrorState } from './home.state';
import { CREATEMEET } from './home.data';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root',
})
export class HomeService extends Store.AbstractService {

  load(payload: CreateMeetLoadPayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new CreateMeetLoadingState());
    }, 0);

    /// DEV CODE ----------------->

    // setTimeout(() => {
    //   output.next(new CreateMeetLoadedState(CREATEMEET));
    //   output.complete();
    // }, 2000);

    // setTimeout(() => {
    //   output.error(new CreateMeetLoadingErrorState(new Error()));
    // }, 2000);

    /// <----------------- DEV CODE

    const path = environment.api.meeting.load.endpoint;

    me.controller.post(path, payload).subscribe(
      (data: CreateMeet) => {
        output.next(new CreateMeetLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new CreateMeetLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  createLead(payload: CreateMeetLoadPayload): Observable<Store.State> {

    const me = this;

    const path = environment.api.meeting.leads.endpoint;
    return me.controller.post(path, payload);


    // const me = this;
    // const output = new Subject<Store.State>();

    // setTimeout(() => {
    //   output.next(new CreateMeetLoadingState());
    // }, 0);

    // // Change Path
    // const path = environment.api.meeting.leads.endpoint;

    // me.controller.post(path, payload).subscribe(
    //   (data: CreateMeet) => {
    //     output.next(new CreateMeetLoadedState(data));
    //     output.complete();
    //   },
    //   (e: any) => {
    //     output.error(new CreateMeetLoadingErrorState(e));
    //     output.complete();
    //   }
    // );

    // return output;
  }
}
