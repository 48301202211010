import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'timeConvert'
})
export class TimeConvertPipe extends 
DatePipe implements PipeTransform {
  transform(value: any): any {
    if ((value !== -1)) {
      const hours = Math.floor(value / 60);
      const minutes = (value - hours * 60);
      const seconds = value - minutes * 60;
      if (hours < 10) {
        if (minutes < 10) {
          if (seconds < 10) {
            return ("0" + hours + ":" + "0" + minutes + ":" + "0" + seconds)
          }
          return ("0" + hours + ":" + "0" + minutes + ":" + seconds)
        }
        return ("0" + hours + ":" + minutes + ":" + seconds)
      }
      else if (minutes < 10) {
        if (seconds < 10) {
          return (hours + ":" + "0" + minutes + ":" + "0" + seconds)
        }
        return (hours + ":" + "0" + minutes + ":" + seconds)
      }
      else {
        if (seconds < 10) {
          return (hours + ":" + minutes + ":" + "0" + seconds)
        }
        return (hours + ":" + minutes + ":" + seconds)
      }
      // return super.transform(value, "hh:mm:ss");
    }
    return "-";
  }

}
