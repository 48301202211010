import { MeetingClientComponent } from 'src/app/shared/meeting-client/meeting-client.component';
import { ElementRef, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/core/session/session.service';
import {
  MeetingClientLocalStreamMediaAccessNotAllowedError,
  MeetingConnection,
  MeetingEvent,
  MeetingLobby,
  MeetingMessage,
} from 'src/app/pages/meeting/service/meeting.model';
import { TokboxConfig } from './service/tokbox-meeting-client.model';
import { TokboxMeetingClientService } from './service/tokbox-meeting-client.service';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTooltip } from '@angular/material/tooltip';
import {
  MatDialog,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscriber } from '@opentok/client';
import { EndCallComponent } from '../../end-call/end-call.component';
import { disconnect } from 'process';
import { SnackBarService } from '../../common/services/snack-bar.service';
@Component({
  selector: 'app-tokbox-meeting-client',
  templateUrl: './tokbox-meeting-client.component.html',
  styleUrls: ['./tokbox-meeting-client.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MeetingClientComponent,
      useExisting: TokboxMeetingClientComponent,
    },
  ],
})
export class TokboxMeetingClientComponent
  extends MeetingClientComponent
  implements OnDestroy
{
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  session: OT.Session;
  streams: Array<OT.Stream> = [];

  publisher: OT.Publisher;
  subscriber: OT.Subscriber;

  allowVideo: boolean;
  allowAudio: boolean;
  subscriberAudio: boolean;
  published: boolean;
  connected: boolean;
  messageLength: number = 0;
  drawerValue: boolean;
  tooltipDisable: boolean;
  dialogFlag: boolean;
  recordingFlag: boolean;
  hostLeaveMeeting: boolean = false;
  chatOpened: boolean = false;

  private setDefaults() {
    const me = this;
    const initAudioVideoValue = JSON.parse(
      sessionStorage.getItem('audioVideoValue')
    );
    me.allowVideo = initAudioVideoValue?.videoValue;
    me.allowAudio = initAudioVideoValue?.audioValue;

    me.subscriberAudio = false;
    me.published = false;
    me.connected = false;
    me.drawerValue = true;
  }

  constructor(
    public sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
    private tokboxMeetingClientService: TokboxMeetingClientService,
    public router: Router,
    public route: ActivatedRoute,
    private zone: NgZone,
    public dialog: MatDialog,
    public snackBarService: SnackBarService
  ) {
    super(sessionService, router, route);
    this.setDefaults();
  }

  public init(
    meeting: MeetingLobby,
    meetingConnection: MeetingConnection
  ): boolean {
    const me = this;
    const config: TokboxConfig = meeting?.config?.tokbox;
    console.log(`tokBox comp init---------->`);
    console.log(config);
    if (super.init(meeting, meetingConnection)) {
      me.tokboxMeetingClientService
        .initSession(config)
        .then((session: OT.Session) => {
          me.session = session;

          me.session.on('streamCreated', (event) => {
            me.streams.push(event.stream);
            me.hostLeaveMeeting = false;
            me.changeDetectorRef.detectChanges();
          });
          me.session.on('streamDestroyed', (event) => {
            const idx = me.streams.indexOf(event.stream);
            if (idx > -1) {
              me.streams.splice(idx, 1);
              me.hostLeaveMeeting = true;
              me.changeDetectorRef.detectChanges();
            }
          });
        })
        .then(() => {
          me.tokboxMeetingClientService.connect();
          return true;
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return false;
  }

  public toggleVideo(value: boolean): void {
    const me = this;
    if (value === true) {
      me.allowVideo = true;
      me.publisher.publishVideo(true);
    } else {
      me.allowVideo = false;
      me.publisher.publishVideo(false);
    }
  }

  public toggleAudio(value: boolean): void {
    const me = this;

    if (value === true) {
      me.allowAudio = true;
      me.publisher.publishAudio(true);
    } else {
      me.allowAudio = false;
      me.publisher.publishAudio(false);
    }
  }

  public leaveCall(): void {
    const me = this;
    me.published = false;
    me.connected = false;
    me.session.off();
    me.session.disconnect();
    me.session.unpublish(me.publisher);
    me.publisher.destroy();
    me.session.unsubscribe(me.subscriber);
    me.meetingConnection.disconnect();
    me.zone.run(() => {
      me.router.navigate(['.', 'feedback'], { relativeTo: me.route });
    });
  }
  ngOnInit() {
    const me = this;
    console.log(`tokBox comp init---------->`);
  }
  ngAfterViewInit() {
    console.log(`tokBox comp init---------->`);
    const me = this;
    const OT = me.tokboxMeetingClientService.getOT();
    me.publisher = OT.initPublisher(me.publisherDiv.nativeElement, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      audioSource: true,
      videoSource: true,
    });
    const initAudioVideoValue = JSON.parse(
      sessionStorage.getItem('audioVideoValue')
    );
    me.publisher.publishAudio(initAudioVideoValue?.audioValue);
    me.publisher.publishVideo(initAudioVideoValue?.videoValue);

    if (me.session) {
      if (me.session['isConnected']()) {
        me.publish();
      }
      me.session.on('sessionConnected', () => me.publish());

      me.session.on('streamCreated', function (event) {
        me.subscriber = me.session.subscribe(
          event.stream,
          me.subscriberDiv.nativeElement,
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            showControls: false,
            // preferredResolution: { width: 680, height: 420 },
          }
        );
        me.subscriberAudio = event.stream.hasAudio;
      });
      me.session.on('streamPropertyChanged', function (event) {
        var subscribers = me.session.getSubscribersForStream(event.stream);
        if (subscribers.length) {
          me.subscriberAudio = event.stream.hasAudio;
        }
      });
    }
    history.pushState(null, null, location.href); // browser back button action disabled
    window.onpopstate = function () {
      history.go(1);
    };

    me.meetingConnection
      .on(MeetingEvent.StartRecording)
      .subscribe((value: MeetingMessage) => {
        console.log(value);

        if (value.type === '3011') {
          me.recordingFlag = true;
        }
      });
    me.meetingConnection
      .on(MeetingEvent.StopRecording)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3012') {
          me.recordingFlag = false;
        }
      });
    me.meetingConnection
      .on(MeetingEvent.PingPong)
      .subscribe((value: MeetingMessage) => {
        if (value.type === '3041') {
          // console.log('Pong');
          me.meetingConnection.sendPong('Pong');
        }
      });
    me.meetingConnection
      .on(MeetingEvent.BatteryLevel)
      .subscribe((value: MeetingMessage) => {
        console.log(value);

        if (value.type === '3040') {
          me.snackBarService.getSnackbar(
            `${this.meeting.hostObject.name}'s phone battery is critically low`
          );
        }
      });
  }
  ngOnDestroy() {
    this.toggleVideo(false);
    this.toggleAudio(false);
  }
  publish() {
    const me = this;
    me.connected = true;
    me.session.publish(me.publisher, (err) => {
      if (err) {
        console.log(err);
      } else {
        me.published = true;
        me.session.on('streamPropertyChanged', function (event) {
          var subscribers = me.session.getSubscribersForStream(event.stream);
          if (subscribers.length) {
            me.subscriberAudio = event.stream.hasAudio;
          }
        });
      }
    });
  }
  tooltipMessage(): number {
    const me = this;
    let message: number;
    me.newMessages = 0;
    return (message = me.chat.messages.length);
  }

  drawerToggle(drawer) {
    const me = this;
    me.drawerValue = !me.drawerValue;
    if (me.drawerValue) {
      me.chatOpened = false;
    } else {
      me.chatOpened = true;
    }
    drawer.toggle();
    console.log(me.tooltipContent);
  }
  onCloseChat(event) {
    const me = this;
    me.drawerValue = !me.drawerValue;
    me.newMessages = 0;
  }
  onEndCall() {
    const me = this;
    let dialogRef = me.dialog.open(EndCallComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        me.leaveCall();
      }
    });
  }
}
