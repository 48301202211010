<mat-toolbar>
  <mat-toolbar-row>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <a fxLayout="row" fxLayoutAlign="center center" routerLink="/home"><img width="50" src="{{site.config.logo}}" alt="Vuport" /></a>
      <div fxFlex fxLayoutAlign="end">
        <a mat-button routerLink="/home">Home</a>
        <a mat-button href="https://www.leftrightmind.com/privacy-policy" target="_blank">Help</a>
        <a mat-button href="https://www.leftrightmind.com/products" target="_blank">About us</a>
        <a mat-button href="https://www.leftrightmind.com/customer-experience" target="_blank" (click)="trackMe()">FAQs</a>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>