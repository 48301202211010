import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FormlyFieldMulticheckboxComponent } from './formly-field-multicheckbox.component';



@NgModule({
  declarations: [FormlyFieldMulticheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatCheckboxModule,

    FormlyMatFormFieldModule,
    FormlySelectModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'field-multicheckbox',
          component: FormlyFieldMulticheckboxComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
})
export class FormlyFieldMulticheckboxModule { }
