<!-- <input *ngIf="type !== 'number'; else numberTmp" matInput [id]="id" [type]="type || 'text'" [readonly]="to.readonly"
  [required]="to.required" [errorStateMatcher]="errorStateMatcher" [formControl]="formControl"
  [formlyAttributes]="field" [tabindex]="to.tabindex" [placeholder]="to.placeholder" />
<i *ngIf="to.editable" class="pass-icon" (click)="editField()" [ngClass]="passwordIcon"></i>
<ng-template #numberTmp>
  <input matInput [id]="id" type="number" [readonly]="to.readonly" [required]="to.required"
    [errorStateMatcher]="errorStateMatcher" [formControl]="formControl" [formlyAttributes]="field"
    [tabindex]="to.tabindex" [placeholder]="to.placeholder" />
</ng-template> -->

<!-- <div class='app-calendar'>
  <full-calendar #calendar defaultView="dayGridMonth" [header]="{
      left: 'prev',
      center: 'title',
      right: 'next'
    }" eventLimit="true" [plugins]="calendarPlugins" [weekends]="calendarWeekends"
    (dateClick)="handleDateClick($event)" (eventClick)="eventClicked($event)"></full-calendar>
</div> -->

<full-calendar [options]="calendarOptions" style="width: 400px;"></full-calendar>