import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

@Pipe({
    name: 'newdatePipe'
})
export class NewDatePipe implements PipeTransform {

    transform(input: string, formatType: string): any {
        const me = this;
        const format = me.dateFormat(formatType);

        if (!input) {
            return null;
        }

        return _moment(input).local().format(format);
        
    }

    dateFormat(formatType: string): string {

        switch (formatType) {
            case 'shortDate':
                return 'DD MMM YYYY';
            case 'normalDateWithTime':
                return 'd/M/yy h:mm a';
            case 'mediumDate':
                return 'DD MMM YYYY  hh:mm A';
            case 'longDate':
                return 'DD MMM YYYY hh:mm:ss A';
            case 'normalDateWithDay':
                return 'dddd, d/M/yy';
            case 'dateWithMonth': return 'MM/DD/YYYY';
            case 'longTime':
                return 'h:mm:ss a';
            case 'mediumTime':
                return 'h:mm';
            case 'customLongDate':
                return 'MM-DD-YYYY | hh:mm:ss';
            case 'longDateWithoutTime':
                return 'DD MMM, YYYY';
            case 'dayDateMonthYear': return 'dddd, DD/MM/YYYY';
            case 'MonthDateYear': return 'MMM DD YYYY';
            case 'dateWithMonthTime': return 'MM/DD/YYYY, hh:mm';
        }
    }

}

export const DateFormat = {
    parse: {
        dateInput: 'input',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

export const GlobalMatDatePickerFormatProvider = [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat }
];


