<div>
    <app-header></app-header>
    <div class="page-container" fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="60">
            <div class="banner" [ngStyle]="{'background-image': 'url(assets/custom/banner-mask.png)'}">
            </div>
        </div>
        <div fxFlex="40">
            <div fxLayout="column" class="content">
                <div>
                    <h4 class="content-title">TAKE A QUICK LIVE DEMO</h4>
                </div>
                <div class="layout-padding">
                    <h4>Technical Advice</h4>
                    <p>1. Please use Google Chrome to best experience possible.</p>
                    <p>2. Live demo is suitable for desktop PC / Macintosh, laptop, internet-enabled mobile devices.</p>
                    <p>3. We recommend using a headeset for optional communication via audia. In case of visual or sound
                        interferences, please refer to our FAQs.</p>

                    <h4>Please Fill In The Details Below</h4>

                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                        <!-- <app-fields></app-fields> -->
                        <button mat-raised-button color="primary" [disabled]="!form.valid">Request Live Demo</button>
                    </form>
                </div>
            </div>
        </div>


    </div>
</div>