import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldVerifyOtpComponent } from './formly-field-verify-otp.component';
import { MatIconModule } from '@angular/material/icon';

const formlyFieldMap = [
  {
    name: 'verify-otp-field',
    component: FormlyFieldVerifyOtpComponent
  }
];

const components = [
  FormlyFieldVerifyOtpComponent
];

const dependencies = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MatButtonModule,
  MatIconModule
];


const modules = [dependencies];

@NgModule({
  declarations: components,
  entryComponents: components,
  imports: [
    modules,
    FormlyModule.forRoot({
      types: formlyFieldMap,
    })
  ],
  exports: [modules, components]
})
export class FormlyFieldVerifyOtpModule { }


