<div>
  <div class="page-container" fxLayout="row" fxLayoutAlign="start" *ngIf="!showBookSlotFlag">
    <div fxFlex.gt-sm=" 40%" fxFlex.gt-xs="100%" class="form-container">
      <div fxLayout="column" class="content">
        <div class="logo" fxLayout="row" fxLayoutAlign="center center">
          <img src="{{ sessionService.site.config.logo }}" alt="Vuport logo" />
        </div>
        
        <div>
          <!-- <h4 class="content-title">take a quick live demo</h4> -->
          <h4 class="content-title">live demo</h4>
        </div>
        <div class="layout-padding login-form-fields">
          <form [formGroup]="form" #formDirective="ngForm">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
            <!-- <button [disabled]="!form.value.customer_agree_terms" [ngClass]="{
                'demo-button': form.value.customer_agree_terms,
                'demo-disable': !form.value.customer_agree_terms
              }" (click)="liveDemo()">
              <span fxLayout="row" fxLayoutAlign="start center">
                Request Demo
              </span>
            </button> -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="select-slot-button-container">
                <button [disabled]="!form.value.customer_agree_terms || !form.valid || liveClicked" [ngClass]="{
                            'demo-button': form.value.customer_agree_terms && form.valid,
                            'demo-disable': !form.value.customer_agree_terms || !form.valid || liveClicked
                          }" (click)="submit(formDirective)">
                  <span fxLayout="row" fxLayoutAlign="start center" style="font-family: Comfortaa;">
                    Book a Demo
                  </span>
                </button>
              </div>
              <div>
                <button type="submit" [disabled]="!form.value.customer_agree_terms || !form.valid || liveClicked" [ngClass]="{
                            'demo-button': form.value.customer_agree_terms && form.valid,
                            'demo-disable': !form.value.customer_agree_terms || !form.valid || liveClicked
                          }" (click)="liveDemo()">
                  <span fxLayout="row" fxLayoutAlign="start center">
                    Live Demo
                  </span>
                </button>
              </div>
              <div class="spinner">
                <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="20">
                </mat-progress-spinner>
              </div>
            </div>

            <!-- <button [disabled]="!form.value.customer_agree_terms" [ngClass]="{
                'demo-button': form.value.customer_agree_terms,
                'demo-disable': !form.value.customer_agree_terms
              }" (click)="submit()">
              <span fxLayout="row" fxLayoutAlign="start center">
                Select a Slot
              </span>
            </button> -->
          </form>
        </div>
      </div>
    </div>
    <div fxFlex.gt-sm="60%" fxHide.xs class="image-container">
      <div class="banner" [ngStyle]="{
          'background-image':
            'url(' + sessionService.site.config.brandBannerImg + ')'
        }"></div>
    </div>

  </div>
  <div *ngIf="showBookSlotFlag">
    <app-book-slot [formData]="formData" (slotEvents)="slotEvents($event)"></app-book-slot>
  </div>
</div>

<!-- [ngClass]="{'demo-button': form.valid , 'demo-disable':!form.valid }" -->