import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SessionService } from 'src/app/core/session/session.service';
import { Store } from 'src/app/core/store/store';
import { BookSlotService } from './service/book-slot.service';
import {
  AvailableBookSlotLoadedState,
  AvailableBookSlotLoadingErrorState,
  AvailableBookSlotLoadingState,
  BookSlotLoadedState,
  BookSlotLoadingErrorState,
  BookSlotLoadingState,
} from './service/book-slot.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { DemoBookedComponent } from 'src/app/shared/demo-booked/demo-booked.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeSlotsUnavailableComponent } from 'src/app/shared/time-slots-unavailable/time-slots-unavailable.component';
import { S } from '@angular/cdk/keycodes';

export interface SlotEvents {
  action: any;
}

@Component({
  selector: 'app-book-slot',
  templateUrl: './book-slot.component.html',
  styleUrls: ['./book-slot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookSlotComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() formData: any;
  @Output() slotEvents: EventEmitter<SlotEvents> = new EventEmitter();

  form = new FormGroup({});
  bookslotform = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  data: any = {};
  empty: boolean;
  error: boolean;
  loading: boolean;
  slots: [];
  selectedSlots: {};
  formattedDate: any;
  newFormData: any;
  showBookSlotFlag: boolean;
  localTimeZone: any;

  demoBooked: any;
  rescheduleDemo: string;
  liveDemoData: any;
  errormsg: any;
  selectedDate: any;
  selectedTimeSlot: any;
  currentDate: any;
  currentTime: any;
  btnDisable: boolean = false;

  constructor(
    public sessionService: SessionService,
    public bookSlotService: BookSlotService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const me = this;
    me.loadSearchSlotForm();
    console.log('form data in book slot component', me.formData);
    me.newFormData = me.formData;

    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function (event) {
      history.pushState(null, document.title, location.href);
    });

    // me.rescheduleDemo = localStorage.getItem('rescheduleDemo');
    me.route.queryParams.subscribe((params) => {
      if (params.demo) {
        me.rescheduleDemo = params.demo;
        // me.liveDemoData = me.formData;
        // console.log("Live Data",me.liveDemoData)
      }
    });

    let newTime = new Date();
    let timeInterval = setInterval(() => {
      newTime = new Date();
    }, 1000);
    let timeInterval1 = setInterval(() => {
      me.currentTime = newTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }, 1000);
    // me.currentTime = Date.now();


    // const timeSlotsData = me.bookslotform.value.availableSlots;
    // me.selectedSlots = timeSlotsData?.startTime;

    let time = new Date().toUTCString();
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const reqPayload: CreateMeetLoadPayload = {
    //   request: {
    //     prospectForm: FormlyUtility.toValue(me.model, me.fields),
    //   },
    //   scheduledOn: time,
    //   timeZone: timeZone
    // };
    me.liveDemoData = {
      request: { prospectForm: JSON.parse(localStorage.getItem('liveDemoData')) },
      scheduledOn: time,
      timeZone: timeZone
    }
    // me.liveDemoData = JSON.parse(localStorage.getItem('liveDemoData'));
    console.log('Live Demo data in book slot component', me.liveDemoData);
    localStorage.removeItem('liveDemoData');
  }
  private loadSearchSlotForm(): void {
    const me = this;
    me.currentDate = new Date();
    me.currentDate.setDate(me.currentDate.getDate() + 1);
    me.fields = [
      {
        fieldGroupClassName: 'col-xs-12',
        fieldGroup: [
          // {
          //   key: 'selectDateLabel',
          //   templateOptions: { label: 'Select Date' },
          //   type: 'read-only-field',
          // },
          {
            className: 'datepickerColor',
            key: 'Datepicker',
            // type: 'datepicker',
            type: 'field-calendar',
            templateOptions: {
              // placeholder: 'MM/DD/YYYY',
              // appearance: 'outline',
              required: true,
              // disabled: true,
              // datepickerOptions: {
              //   min: currentDate,
              // },
            },
          },
          {
            key: 'selectTimeLabel',
            templateOptions: { label: 'Choose Time Slot' },
            type: 'read-only-field',
          },
          {
            // className: 'col-xs-12',
            key: 'availableSlots',
            type: 'select',
            templateOptions: {
              placeholder: '00:00 - 00:00',
              appearance: 'outline',
              required: true,
              disabled: true,
            },
            hooks: {
              onInit(field: FormlyFieldConfig): void {
                console.log(me.model);
                me.bookslotform
                  .get('Datepicker')
                  .valueChanges.pipe(debounceTime(600), distinctUntilChanged())
                  .subscribe((name) => {
                    console.log('datefield', name);
                    me.selectedDate = name;
                    // localStorage.getItem('selectedDate');
                    // me.formattedDate = moment(name).format('YYYY-MM-DD');
                    me.formattedDate = name;
                    console.log("me.formattedDate", me.formattedDate);
                    // field.templateOptions.options = me.bookSlotService.searchAvailableSlots(me.formattedDate);

                    me.bookSlotService.searchAvailableSlots(me.formattedDate).subscribe((res) => {
                      console.log("book slot response", res);
                      if (res) {
                        // field.templateOptions.options = me.bookSlotService.searchAvailableSlots(me.formattedDate);
                        field.templateOptions.options = res;

                        // if (field.templateOptions.valueProp == null) {
                        //   me.openTimeSlotsUnavailableComponent();
                        // }
                      }
                    },
                      (err: HttpErrorResponse) => {
                        console.log("Error", err.error.message);
                        if (err.status == 403) {
                          me.openTimeSlotsUnavailableComponent();
                          field.templateOptions.disabled = true;
                          me.btnDisable = true;
                        }
                      }
                    );
                    console.log(me.bookSlotService.searchAvailableSlots(me.formattedDate));
                    field.templateOptions.disabled = false;

                  },
                    (err: HttpErrorResponse) => {
                      console.log("Error", err.error.message);
                      if (err.error instanceof Error) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', err.error.message);
                      } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                      }
                    });

                me.bookslotform
                  .get('availableSlots')
                  .valueChanges.pipe(debounceTime(600), distinctUntilChanged())
                  .subscribe((slots) => {
                    console.log('slots', slots);
                    me.selectedSlots = slots;
                    me.btnDisable = false;
                    // for (let key in slots) {
                    //   console.log("key:", key, "value:", slots[key]);
                    //   me.selectedSlots = slots[key];
                    // }
                  });
              },
            },
          },
        ],
      },
    ];
  }

  openTimeSlotsUnavailableComponent() {
    const me = this;
    const dialogRef = me.dialog.open(TimeSlotsUnavailableComponent, {
      // width: '50%'
      panelClass: 'my-custom-dialog-class',
      disableClose: true
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result && result.getData === false) {
    //     me.slotEvents.emit({ action: 'submit' });
    //   }
    // });

  }

  openDemoBookedComponent() {
    const me = this;
    const dialogRef = me.dialog.open(DemoBookedComponent, {
      // width: '50%'
      panelClass: 'my-custom-dialog-class',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.getData === false) {
        me.slotEvents.emit({ action: 'submit' });
      }
    });

  }

  submit() {
    const me = this;
    // me.bookslotform.value.availableSlots.templateOptions.disabled = true;
    // me.fields[0].fieldGroup[0].templateOptions.disabled = true;
    // me.fields[0].fieldGroup[2].templateOptions.disabled = true;
    // me.bookslotform.value.Datepicker.disabled = true;
    let date = new Date();
    const formattedDate = moment(date).format('YYYY-MM-DD');
    me.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // const prospectFormData = me.value.dataKey;
    const timeSlotsData = me.bookslotform.value.availableSlots;
    // me.selectedSlots = timeSlotsData?.startTime;
    let payloadData: any;
    if (me.rescheduleDemo == 'reschedule') {
      let liveData;
      liveData = {
        "request": {
          "prospectForm": [
            {
              "key": "customer_firstName",
              "values": [
                me.liveDemoData.request.prospectForm.customer_firstName,
                me.liveDemoData.request.prospectForm.customer_firstName
              ],
              "label": "FIRST NAME",
              "type": "field-name"
            },
            {
              "key": "customer_lastName",
              "values": [
                me.liveDemoData.request.prospectForm.customer_lastName,
                me.liveDemoData.request.prospectForm.customer_lastName
              ],
              "label": "LAST NAME",
              "type": "field-last-name"
            },
            {
              "key": "customer_email",
              "values": [
                me.liveDemoData.request.prospectForm.customer_email,
                me.liveDemoData.request.prospectForm.customer_email
              ],
              "label": "EMAIL ID",
              "type": "field-email"
            },
            {
              "key": "customer_phone",
              "values": [
                me.liveDemoData.request.prospectForm.customer_phone,
                me.liveDemoData.request.prospectForm.customer_phone
              ],
              "label": "MOBILE NUMBER",
              "type": "field-mobile"
            },
            {
              "key": "product",
              "values": [
                me.liveDemoData.request.prospectForm.product,
                me.liveDemoData.request.prospectForm.product
              ],
              "label": "PRODUCT",
              "type": "input"
            },
            {
              "key": "customer_agree_terms",
              "values": [
                [
                  me.liveDemoData.request.prospectForm.customer_agree_terms,
                  "customer_agree_terms"
                ]
              ],
              "label": "I agree to the",
              "type": "single-checkbox"
            }
          ]
        }
      }

      payloadData = {
        // ...prospectFormData,
        ...liveData,
        date: me.formattedDate,
        slotStartTime: timeSlotsData?.startTime,
        slotEndTime: timeSlotsData?.endTime,
        localTimeZone: me.localTimeZone
      };
    }
    else {
      payloadData = {
        // ...prospectFormData,
        ...me.newFormData,
        date: me.formattedDate,
        slotStartTime: timeSlotsData?.startTime,
        slotEndTime: timeSlotsData?.endTime,
        localTimeZone: me.localTimeZone
      };
    }
    me.bookDemo(payloadData);
    me.btnDisable = true;
  }
  bookDemo(payload) {
    const me = this;
    me.fields[0].fieldGroup[2].templateOptions.disabled = true;
    me.bookSlotService.bookSlot(payload).subscribe(
      (state: Store.State) => {
        if (state instanceof BookSlotLoadingState) {
          me.onLoading(state);
          return;
        }
        if (state instanceof BookSlotLoadedState) {
          me.onLoaded(state);
          console.log(state);
          me.loading = false;

          me.openDemoBookedComponent();

          return;
        }
      },
      (state: BookSlotLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  goBack() {
    const me = this;
    me.slotEvents.emit({ action: 'back' });
  }

  getSnackbar(message: string, duration?: string) {

    const me = this;
    const minDuration = 3000;
    const mediumDuration = 4000;
    const maxDuration = 5500;
    const defaultDuration = 2500;

    switch (duration) {
      case 'minDuration':
        return me.openSnackBar(message, minDuration);
      case 'mediumDuration':
        return me.openSnackBar(message, mediumDuration);
      case 'maxDuration':
        return me.openSnackBar(message, maxDuration);
      default:
        return me.openSnackBar(message, defaultDuration);
    }
  }

  openSnackBar(message: string, time: number) {
    const me = this;
    return me.snackBar.open(message, 'x', {
      duration: time,
      verticalPosition: 'bottom',
      horizontalPosition: 'left'
    });
  }

  showErrorMessage(error) {
    const me = this;
    return me.getSnackbar((error?.error?.message)
      || (error?.error?.error?.message)
      || 'Internal server error', 'mediumDuration');
  }

  private onLoading(state: BookSlotLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: BookSlotLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: BookSlotLoadedState) {
    const me = this;
    me.data = state.data;
    me.error = false;
    me.loading = false;
    if (me.data) {
      console.log(me.data.data);
      me.showBookSlotFlag = false;
    }
  }
}
