import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-field-product',
  templateUrl: './formly-field-product.component.html',
  styleUrls: ['./formly-field-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldProductComponent extends FieldType implements OnInit {
  passwordIcon: string = 'icons8-edit';
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      label: 'PRODUCT',
      placeholder: 'Enter Product Name',
      appearance: 'outline',
      minLength: 3,
      maxLength: 25,
      // pattern: /^[A-Za-z]*$/,
    },
    // validation: {
    //   messages: {
    //     // pattern: (error, field: FormlyFieldConfig) => `Enter Valid Product`,
    //     minLength: (error, field: FormlyFieldConfig) => `Product name must be between 3-25 characters ${error.requiredLength}`,
    //     maxLength: (error, field: FormlyFieldConfig) => `Product name must be between 3-25 characters ${error.requiredLength}`,
    //   },
    // },
    // validators: {
    //   name: {
    //     expression: (c) => /^[A-Za-z]*$/.test(c.value),
    //     message: (error, field: FormlyFieldConfig) => "Enter a valid name",
    //   },
    // },
  };

  get type() {
    return this.to.type || 'text';
  }
  ngOnInit() {
    const me = this;
    if (me.to.editable) {
      me.to.disabled = true;
    }
  }
  editField() {
    const me = this;
    me.to.disabled = !me.to.disabled
  }
}
