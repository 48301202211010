import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDisplayComponent } from './product-display.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PdfViewerModule } from 'ng2-pdf-viewer';


const imports = [
  FlexLayoutModule,
  PdfViewerModule,
]

@NgModule({
  declarations: [ProductDisplayComponent],
  exports: [ProductDisplayComponent],
  imports: [
    CommonModule,
    imports
  ]
})
export class ProductDisplayModule { }
