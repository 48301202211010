<div class="container">
    <div fxFlex.gt-sm="40%" fxFlex.gt-xs="100%">
        <div fxLayout="column" class="content">
            <!-- <span (click)="goBack()" class="go-back">&times;</span> -->
            <span *ngIf="rescheduleDemo != 'reschedule'"><a mat-button matTooltip="Back" matTooltipPosition="after"
                    class="back-button">
                    <img src="https://img.icons8.com/ios/50/000000/circled-left-2.png" (click)="goBack()" />
                </a></span>
            <div class="logo" fxLayout="row" fxLayoutAlign="center center">
                <img src="{{ sessionService.site.config.logo }}" alt="Vuport logo" />
            </div>
            <div>
                <span *ngIf="rescheduleDemo == 'reschedule'">
                    <h4 class="content-title" style="font-family: Comfortaa-Bold;">reschedule demo</h4>
                </span>
                <span *ngIf="rescheduleDemo != 'reschedule'">
                    <h4 class="content-title">book a demo</h4>
                </span>
                <div class="formly-fields" fxLayout="row" fxLayoutAlign="center center">
                    <form [formGroup]="form" #formDirective="ngForm">
                        <div class="calendar-header">
                            <img src="./assets/custom/icons8-today-500.svg">
                            <span class="date" *ngIf="!selectedDate || !selectedSlots">{{currentDate | customdatePipe:
                                'MonthDateYear'}}, {{currentTime}}</span>
                            <span class="date" *ngIf="selectedDate && selectedSlots">{{selectedDate | newdatePipe: 'MonthDateYear'}}, 
                                <!-- {{selectedSlots}} -->
                                <span *ngFor="let item of selectedSlots | keyvalue">
                                    <!-- <span *ngIf="item.key == 'endTime'">- {{item.value}}</span> -->
                                    <span *ngIf="item.key == 'startTime'"> {{item.value}}</span>
                                </span> 
                                -
                                <span *ngFor="let item of selectedSlots | keyvalue">
                                    <span *ngIf="item.key == 'endTime'">{{item.value}}</span>
                                    <!-- <span *ngIf="item.key == 'startTime'"> {{item.value}}</span> -->
                                </span> 
                            </span>
                        </div>
                        <formly-form [model]="model" [fields]="fields" [options]="options" [form]="bookslotform">
                        </formly-form>
                        <div class="request-demo">
                            <!-- <button class="demo-button request-demo"><span>Request Demo</span></button>
                            <div class="spinner">
                                <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="20">
                                </mat-progress-spinner>
                            </div> -->
                            <!-- <div fxLayout="row" fxLayoutAlign="center"> -->
                            <button type="submit" [disabled]="!bookslotform.valid || btnDisable"
                                [ngClass]="{'demo-disable': !bookslotform.valid || btnDisable, 'demo-button': bookslotform.valid || !btnDisable}"
                                (click)="submit()">
                                <span fxLayout="row" fxLayoutAlign="center">Submit</span>
                            </button>
                            <!-- [ngClass]="{'demo-button': form.valid,'demo-disable': !form.valid}" -->
                            <div class="spinner" *ngIf="bookslotform.valid">
                                <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="20">
                                </mat-progress-spinner>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex.gt-sm="60%" fxHide.xs class="image-container">
        <div class="banner" [ngStyle]="{
            'background-image':
              'url(' + sessionService.site.config.brandBannerImg + ')'
          }"></div>
    </div>
</div>