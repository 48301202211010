import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from 'src/app/core/store/store';

@Injectable({
    providedIn: 'root'
})
export class FormlyFieldVerifyOtpService extends Store.AbstractService {

    constructor(private http: HttpClient) {
        super();
    }

    generateOTP(): Observable<any> {
        const me = this;
        const apiConfig = '/otp/generate';
        return me.controller.post(apiConfig);
    }

    verifyOTP(otp: number): Observable<any> {
        const me = this;
        const apiConfig = '/otp/verify';
        return me.controller.post(apiConfig, otp);
    }


}
