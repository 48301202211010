import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Subscriptions } from 'src/app/utility/common.model';
import { IDGenerator } from 'src/app/utility/id-generator.util';
import { AppLocalStorage } from 'src/app/utility/local-storage.util';
import { environment } from 'src/environments/environment';
import { Store } from '../store/store';
import { LoginPayload, Session } from './session.model';
import { SessionCreatedState, SessionCreatingState, SessionCreatingErrorState, SessionDestroyingState, SessionDestroyedState, SessionDestroyingErrorState } from './session.state';
import { Location } from '@angular/common';
import { AppError } from '../error/error.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends Store.AbstractService {

  private static RESTRICTED_REDIRECT_URLS: string[] = [
    '/login',
    '/logout',
    '/loading'
  ];

  initiated: boolean = false;

  private redirectURL: string;
  private redirectToken: string;
  private stateSession: SessionCreatedState;
  session: Session;
  site: any; // TODO: create data type, since object structure is not finalized we are not using

  private subscriptions: Subscriptions = {
    sessionValidate: null
  };

  constructor(private router: Router, private location: Location) {
    super();
    const me = this;

    me.router.events.subscribe((re: RouterEvent) => {
      me.onRouterEvent(re);
    });

  }

  init() {
    const me = this;
    me.setRedirectURL();
    me.redirectToLoadingPage();
    return new Promise((resolve, reject) => {
      me.initSite(resolve, reject);
    });
  }

  isActive(): boolean {
    const me = this;
    if (me.stateSession) {
      return me.stateSession instanceof SessionCreatedState;
    }
    return false;
  }

  redirectToHomePage() {
    const me = this;
    const redirectTo = me.redirectURL || '/home';
    const params: Params = me.getPramsFromURL(redirectTo);
    me.router.navigate([me.cleanURL(redirectTo)], { queryParams: params });
  }

  private cleanURL(url: string): string {
    return url.split('?')[0]
  }
  

  private getPramsFromURL(url: string): Params {
    if (url.indexOf('?')) {
      url = url.substring(url.indexOf('?')).replace('%26', '&')
      const searchParams = new URLSearchParams(url);
      const params: Params = {};

      searchParams.forEach((v, k) => {
        params[k] = v;
      });

      return params;
    }
    return null;
  }

  redirectToPostLoginPage() {
    const me = this;
    me.redirectToHomePage();
  }

  redirectToLoginPage(skipWelcome?: boolean) {
    const me = this;
    me.redirectURL = "/login"    // "/login"    
    const redirectTo = me.redirectURL || '/login';

    // this.router.navigate['/login'];

    const params: Params = me.getPramsFromURL(redirectTo);
    me.router.navigate([me.cleanURL(redirectTo)], { queryParams: params });

  }

  redirectToLoadingPage() {
    const me = this;
    me.redirectToken = IDGenerator.newId();
    me.router.navigate(['/loading']);
  }

  // Action: Login
  login(rawCredential: LoginPayload): Observable<Store.State> {
    const output = new Subject<Store.State>();
    const me = this;
    const credential: LoginPayload = me.prepareLoginPayload(rawCredential);

    me.stateSession = new SessionCreatingState();

    setTimeout(() => {
      output.next(me.stateSession);
    }, 0);

    me.controller.post(environment.api.session.login.endpoint, credential, environment.api.session.login.base)
      .subscribe((data: Session) => {
        me.stateSession = new SessionCreatedState(data);
        console.log("after login");
        me.onSessionCreated();
        // me.autoLogout();
        output.next(me.stateSession);
        output.complete();
        
      }, (e: HttpErrorResponse) => {
        me.stateSession = new SessionCreatingErrorState(AppError.fromError(e));
        output.error((me.stateSession as SessionCreatingErrorState).error);
        output.complete();
      });
    return output;
  }

  autoLogout(){
    console.log("auto logout");
    setTimeout(() => {
      this.logout();
    }, 5000);

  }

  validate(): Observable<void> {
    const me = this;
    const output: Subject<void> = new Subject<void>();

    if (me.session) {
      const payload = {};

      me.controller.get(environment.api.session.validate.endpoint, payload, environment.api.session.validate.base)
        .subscribe((data) => {
          output.next();
        }, (e: HttpErrorResponse) => {
          if (e.status === 400) {
            me.router.navigate(['/logout']);
          }
          output.next();
        });
    } else {
      setTimeout(() => {
        output.next();
      });
    }

    return output;
  }
//Sahil changes
  // autoLogout(expirationDate : number){
  //   setTimeout(() => {
  //     this.logout();
  //   }, expirationDate);

  // }

  logout() {
    const me = this;
    const data = {};
    me.stateSession = new SessionDestroyingState();

    me.controller.get(environment.api.session.logout.endpoint, data, environment.api.session.logout.base)
      .subscribe(() => {
        me.clearSession();
        me.stateSession = new SessionDestroyedState();
      }, (e: any) => {
        me.clearSession();
        me.stateSession = new SessionDestroyingErrorState(e);
        me.logger.error((me.stateSession as SessionDestroyingErrorState).error);
      });
  }

  private setRedirectURL(url?: string) {
    const me = this;
    let redirectURL: string = url || me.location.path();
    console.log(redirectURL);


    if (SessionService.RESTRICTED_REDIRECT_URLS.indexOf(redirectURL) !== -1) {
      redirectURL = null;
    }

    me.redirectURL = redirectURL;
  }

  private clearSession() {

  }

  private onSessionCreated() {
    const me = this;
    
    // this.autoLogout();
  }

  private prepareLoginPayload(credential: LoginPayload): LoginPayload {
    return credential;
  }

  private initSite(resolve: (() => void), reject: (() => void)) {
    const me = this;
    const path = environment.api.site.load.endpoint;
    me.site = AppLocalStorage.get('SITE', 'SITE');

    if (me.site) {
      
     setTimeout(() => {
        resolve();
        me.initiated = true;
        me.onSiteLoaded();
      });
    } else {
      me.controller.get(path).subscribe(
        (data: any) => {
          me.site = data;
          AppLocalStorage.set('SITE', 'SITE', me.site, 300000); // 15 Mins TTL
        
          setTimeout(() => {
            resolve();
            me.initiated = true;
            me.onSiteLoaded();
          });
        },
        () => {
          reject();
          me.router.navigate(['/error']);
        }
      );
    }
  }

  private onSiteLoaded() {
    const me = this;
    me.redirectToHomePage();
    // me.redirectToLoginPage();           // route to admin
    // Brand color config
    const body = document.querySelector('body');
    body.style.setProperty('--brand-color', me.site.config.brandColor || '#555');
  }

  private onRouterEvent(event: RouterEvent) {
    const me = this;

    if (event instanceof NavigationStart) {

      switch (event.url) {
        case '/logout':
          me.logout();
          break;
        case '/login':
          if (me.isActive()) {
            me.redirectToPostLoginPage();
          }
          break;
      }

    }

    if (event instanceof NavigationEnd) {

      switch (event.url) {
        case '/loading':
          me.redirectToken = null;
          break;
      }

    }
  }

}
