import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CountryCallingCode, CountryCode, getPhoneCode } from 'libphonenumber-js';
import { Observable } from 'rxjs';
import { CountryCodeModel } from './service/formly-field-country-code-select.model';
import { FormlyFieldCountryCodeSelectService } from './service/formly-field-country-code-select.service';
@Component({
  selector: 'app-formly-field-country-code-select',
  templateUrl: './formly-field-country-code-select.component.html',
  styleUrls: ['./formly-field-country-code-select.component.scss']
})
export class FormlyFieldCountryCodeSelectComponent extends FieldType implements OnInit {

  countryCodeControl = new FormControl();
  countryCodes: Observable<CountryCodeModel>;

  constructor(private formlyFieldCountryCodeSelectService: FormlyFieldCountryCodeSelectService) {
    super();
    this.countryCodes = this.formlyFieldCountryCodeSelectService.getCountryCodes();
  }

  ngOnInit(): void {
    const me = this;

    me.countryCodeControl.valueChanges.subscribe(value => {
      me.formControl.patchValue(value);
    });
  }

  getCountryCallingCode(countryCode: CountryCode): CountryCallingCode {
    return getPhoneCode(countryCode);
  }

  getManipulatedValues(flag: string, code: CountryCode) {
    return code;
  }

}
