import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { CommonValidatorsMap } from '../../validations/validators';

@Component({
  selector: 'app-formly-field-mobile',
  templateUrl: './formly-field-mobile.component.html',
  styleUrls: ['./formly-field-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldMobileComponent extends FieldType implements OnInit {
  passwordIcon: string = 'icons8-edit';
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
  defaultOptions = {
    templateOptions: {
      label: 'PHONE NUMBER',
      type: 'number',
      placeholder: 'Enter a Phone Number',
      appearance: 'outline',
      // pattern: /^[1-9](\d)\1{8}$/g,
      // pattern: '^[1-9][0-9]{9}$',
      pattern: '[1-9][0-9]{9}',
      maxLength: 10,
      // pattern: '[789][0-9]{9}',
    },
    // validators: {
    //   name: {
    //     expression: (c) => /[1-9][0-9]{9,14}/.test(c.value),
    //     message: (error, field: FormlyFieldConfig) => "Enter a valid mobile number",
    //   },
    // },
    validation: {
      messages: {
        // required: CommonValidatorsMap.Required.message,
        pattern: (error, field: FormlyFieldConfig) => `Enter a valid mobile number`,
      },
    },
  };

  get type() {
    return this.to.type || 'text';
  }
  onKeypressEvent(event: any) {
    if (
     ( (event.which != 8 && event.which != 0 && event.which < 48) ||
      event.which > 57 )  && event.key !== 'Tab'
    ) {
      event.preventDefault();
    }
    if (event.target.value.length === 0 && event.key === "0" && event.key !== 'Tab') {
      event.preventDefault();
    }
    // console.log("MOBILE",event);
    // else if(event.target.value.length > 1)
    // {
    //     var enteredValue = String.fromCharCode(event.keyCode);
    //     var lastEnteredValue = event.target.value.substr(-1, 1);
    //     var lastBeforeValue = event.target.value.substr(-2, 1);

    //     if(enteredValue == lastEnteredValue && enteredValue == lastBeforeValue)
    //     {
    //       event.preventDefault();
    //     }
    // }
  }

  ngOnInit() {
    const me = this;
    if (me.to.editable) {
      me.to.disabled = true;
    }

    // document.addEventListener('keypress', function(event) {
    //   var input = (<HTMLInputElement>document.getElementById("id")).value;
    //   console.log(input);
    //   if (input.length === 0 && event.key === "0") {
    //     event.preventDefault();
    //   }
    // }, true);
  }
  editField() {
    const me = this;
    me.to.disabled = !me.to.disabled
  }
}
